import * as launchdarkly from "redux/actions/darklyAction";
import * as LDClient from "launchdarkly-js-client-sdk";
import { v4 as uuid } from "uuid";
import { store } from "redux/store";

export const initializePendo = (userData) => {
   const accountId = userData.user.enterpriseName;
   const departmentName = userData?.user.department || "";
   const hospitalName = userData?.user?.hospitalName || "";
   const departmentId = userData?.user?.departmentId || "";
   const hospitalId = userData?.user?.hospitalId || "";
   var obj = {};
   if (process.env.NODE_ENV === "production") {
      obj = {
         visitor: {
            visitorId: userData?.user?.id,
            id: userData?.user?.id,
            fullName: userData?.user?.name,
            isPatient: true,
            email: userData?.user?.email,
            department: departmentName,
            hospital: hospitalName,
            departmentId: departmentId,
            hospitalId: hospitalId,
            createdAt: userData?.user?.createdAt,
         },

         account: {
            accountId: accountId,
            accountid: accountId,
            id: accountId,
         },
      };
   } else {
      obj = {
         visitor: {
            visitorId: "dev-" + userData?.user?.id,
            id: "dev-" + userData?.user?.id,
            fullName: userData?.user?.name,
            isPatient: true,
            email: userData?.user?.email,
            department: departmentName,
            hospital: hospitalName,
            departmentId: departmentId,
            hospitalId: hospitalId,
            createdAt: userData?.user?.createdAt,
         },

         account: {
            accountId: "dev-" + accountId,
            accountid: "dev-" + accountId,
            id: "dev-" + accountId,
         },
      };
   }
   window.pendo.initialize(obj);
};

export async function initializeLDClient(userInfo) {
   let email = userInfo?.email;
   const user = {
      key: uuid(),
      custom: {
         hospitalName: "",
         enterpriseName: "",
         departmentName: userInfo?.department,
         emailDomain: email?.substring(email?.indexOf("@") + 1),
         role: userInfo?.role,
         deviceType: "web",
      },
   };

   const client = LDClient.initialize(`${process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID}`, user);
   // will fetch status of flags once the client is initialized and ready to serve.
   client.on("ready", () => {
      const flags = client.allFlags(); // get the flag
      store.dispatch(launchdarkly.setLdFeatureFlags(flags)); // store them in redux
   });
   // Will Fetch updated status of flags whenever a flag is changed.
   client.on("change", () => {
      const flags = client.allFlags(); // get the flags
      store.dispatch(launchdarkly.setLdFeatureFlags(flags)); // store them in redux
   });
}

export const initiaLize = (user) => {
   initializeLDClient(user);
};
