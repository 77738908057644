import React from "react";

function ShowImage(props) {
   const { srcImg, isPhotoSelected } = props;
   return (
      <div className="flex justify-center">
         <img
            src={srcImg}
            onError={(e) => {
               e.target.style.opacity = 0;
               e.target.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
            }}
            alt=""
            height={!isPhotoSelected ? "180px" : "252px"}
            width={!isPhotoSelected ? "180px" : "252px"}
            style={{ top: `${!isPhotoSelected ? "10px" : "0px"}`, paddingBottom: "10px" }}
            className={`${!isPhotoSelected && ""} rounded-full`}
         />
      </div>
   );
}

export default ShowImage;
