import React from "react";
import "index.css";
import { connect } from "react-redux";
import SharedPatientDetails from "modules/sharedPatientDetails/Component/sharedPatientDetails";

class SharedPatientDetailsCont extends React.Component {
   render() {
      return <SharedPatientDetails {...this.props} />;
   }
}
const mapStateToProps = (state) => {
   return {
      auth: state.auth,
   };
};

export default connect(mapStateToProps, null)(SharedPatientDetailsCont);
