import React, { useEffect } from "react";
import closesymbol from "images/common/close-symbol.svg";
import Avatar from "shared/components/avatar";
import callButton from "images/login/call-hospital.svg";
import initAutocomplete from "shared/components/googleMaps";
import { loginConst } from "shared/constants/loginConst";
import { callMobileNumber, formatPhoneNumberWithPlusOne } from "shared/actions/helpers";

function HospitalDetailsComp(props) {
   const { hospitalDetails, close = () => {}, nextStep = () => {} } = props;
   const {
      name,
      lat,
      long,
      officeAddress = "",
      officeAddress2 = "",
      officeCity = "",
      officeZip = "",
      phone = null,
      isSelfOnboardAllowed,
   } = hospitalDetails;
   const completeAddress = `${officeAddress} ${officeAddress2} ${officeCity} ${officeZip}`;

   useEffect(() => {
      if (lat && long) {
         initAutocomplete({ lat: lat, long: long });
      }
   }, []);

   return (
      <div className={`bg-white h-full sm:h-auto sm:w-96 w-full justify-center rounded-2xl`}>
         <div className="bg-gray-200 h-[125px] rounded-t-2xl">
            <div className=" relative w-full justify-center rounded-t-2xl">
               <img
                  onClick={close}
                  src={closesymbol}
                  alt="cross-btn"
                  className="absolute w-[25px] h-[25px] bg-gray-200 z-[70] rounded-lg p-[5px] cursor-pointer right-2 top-2"
               />
            </div>
         </div>

         <div className="relative w-full">
            <div className="absolute left-[35%] w-full">
               <Avatar
                  src={`${process.env.REACT_APP_PROFILE_URL}/${hospitalDetails?.id}_logo?id=${Date.now()}`}
                  className="cursor-pointer shadow-md bg-white align-middle bottom-16"
                  radius={120}
                  fit={true}
                  isHospImg={true}
                  bgColor="#043293"
                  name={name}
                  isDetails={true}
               />
            </div>
            <div className="w-full px-6 text-center">
               <div id="hosp-title" className="pt-20 w-full text-xl font-bold">
                  {name}
               </div>
               <div id="hosp-desc" className="pt-2 pb-2">
                  {" "}
                  {completeAddress && completeAddress}
               </div>
               {phone && (
                  <div>
                     <div id="hosp-mobile">{phone && formatPhoneNumberWithPlusOne(phone)}</div>
                     <div className="flex items-center justify-center mt-2">
                        <img
                           className="cursor-pointer"
                           src={callButton}
                           alt="call"
                           onClick={() => {
                              callMobileNumber(phone);
                           }}
                        />
                     </div>
                  </div>
               )}
               {lat && long && (
                  <div className={`w-full ${phone ? "h-40" : "h-64"} pb-4`}>
                     <div id="map" className="w-full h-full mb-2 mt-4"></div>
                  </div>
               )}
               {!isSelfOnboardAllowed && (
                  <div className="pb-3 text-red-500">
                     <button onClick={nextStep}>{loginConst.patientOnboard.requestSignup}</button>
                  </div>
               )}
            </div>
         </div>
      </div>
   );
}

export default HospitalDetailsComp;
