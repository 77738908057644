import { gql } from "@apollo/client";

export const GET_USER_DATA = gql`
   query getProfile($options: GetUserProfileOptions) {
      getProfile(options: $options) {
         id
         isFollow
         hasCreatedContent
         colorCode
         dob
         dateOfBirth
         hospitals {
            name
            id
         }
         departments {
            name
            id
         }
         enterpriseInfo {
            id
            name
         }
         name {
            firstName
            lastName
            fullName
            middleName
            initials
         }
         providerInfo {
            degree
            department
            title
         }
         contactInformation {
            email
            mobileNumber
            officeNumber
            isCallEnable
         }
      }
   }
`;
