import { gql } from "@apollo/client";

export const SET_PATIENT_CONTENT = "SET_PATIENT_CONTENT";
export const SET_CONTENT_SEARCH_TERM = "SET_CONTENT_SEARCH_TERM";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";

export const GET_PATIENT_CONTENT_ACTIVITY = gql`
   query Query(
      $offset: Int!
      $limit: Int!
      $contentFilter: String
      $patientOnly: Boolean
      $providerOnly: Boolean
      $search: String
      $user: UserInput
   ) {
      getPatientContent(
         offset: $offset
         limit: $limit
         contentFilter: $contentFilter
         patientOnly: $patientOnly
         providerOnly: $providerOnly
         search: $search
         user: $user
      ) {
         totalCount
         contents {
            title
            type
            id
            createdAt
            isPrintable
            provider {
               name {
                  fullName
               }
               contactInformation {
                  email
               }
               id
            }
            patient {
               id
               name {
                  fullName
               }
               contactInformation {
                  email
               }
            }
            views {
               viewedAt
               viewer {
                  id
                  name {
                     fullName
                  }
                  contactInformation {
                     email
                  }
               }
            }
            hasThumbnail
            loves
            mentions
            tags
            hospital {
               id
               name
            }
            healthSystem {
               id
               name
               patientIdentifiers {
                  number
                  type
                  authority
               }
            }
         }
      }
   }
`;

export const GET_THUMBNAIL_SIGNED_URL = gql`
   query GetSignedURL($thumbnail: Boolean!, $content: ContentInput!) {
      getSignedURL(thumbnail: $thumbnail, content: $content) {
         url
         thumbnailUrl
      }
   }
`;

export const UPDATE_ITEM_VIEWED_STATUS = gql`
   mutation AddActivity($options: AddActivityOptions) {
      addActivity(options: $options) {
         status {
            code
            success
            message
         }
      }
   }
`;
