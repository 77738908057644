import React, { useEffect, useState } from "react";
import LoadingIndicator from "shared/components/loader";
import { connect } from "react-redux";
import Avatar from "shared/components/avatar";
import { textConst } from "shared/constants/const";
import cross from "images/common/cross.svg";
import { getPatientQRCode } from "actions/activatePatient/activatePatientActions";

function ActivatePatient({ auth, onModalTapped }) {
   const { user } = auth.userCredentials;
   const initialsApi = user.initials;
   const color = user.colorCode || window.initialColors[0];
   const name = user.name;
   const [patientInfo, setPatientInfo] = useState(null);
   const getQrCode = () => {
      getPatientQRCode((data) => {
         setPatientInfo(data);
      });
   };
   useEffect(() => {
      getQrCode();
   }, []);
   const isLoading = patientInfo === null;
   return (
      <div
         className={`${
            isLoading ? " w-full sm:h-[600px]" : "h-full sm:h-max"
         } sm:rounded-[20px] bg-white sm:w-[600px] w-full  text-center flex items-center justify-center relative`}
      >
         {isLoading ? (
            <LoadingIndicator />
         ) : (
            <div className=" text-[23px] flex items-center justify-center flex-col sm:font-sub font-medium text-black p-3 pb-6">
               <img
                  onClick={onModalTapped}
                  src={cross}
                  alt="cross-btn"
                  className="absolute right-5 top-5 cursor-pointer"
               />
               My code
               <div className="mt-4">
                  <Avatar
                     src={`${process.env.REACT_APP_PROFILE_URL}/${user?.id}`}
                     className="cursor-pointer"
                     bgColor={color}
                     radius={60}
                     initialsApi={initialsApi}
                     name={name}
                  />
               </div>
               <div className=" pt-2 text-[23px] font-bold text-black">{name}</div>
               <div className="font-17 font-medium">
                  {user.email}
                  <br />
                  {user.mobileNo}
               </div>
               <div className="w-[400px] h-[300px]">
                  <img
                     src={`https://chart.googleapis.com/chart?cht=qr&chl=${patientInfo}&choe=UTF-8&chs=400x300`}
                     alt="qr-code"
                  />
               </div>
               <div className=" w-[80%] sm:w-full text-sm sm:text-lg font-medium">{textConst.qrCode}</div>
            </div>
         )}
      </div>
   );
}

const mapStateToProps = (state) => {
   return {
      auth: state.auth,
   };
};

export default connect(mapStateToProps, null)(ActivatePatient);
