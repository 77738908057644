import React from "react";
import info from "images/login/info-icon.svg";
import infoWhite from "images/login/info-icon-white.svg";
import Avatar from "shared/components/avatar";

function HealthcareFacilityComp(props) {
   const { id, hospital, selectHospital, selectHospDetails, selectedHospital = null } = props;
   const { name, officeAddress = "", officeAddress2 = "", officeCity = "", officeZip = "" } = hospital;
   const isSelect = selectedHospital?.id === hospital?.id ? true : false;
   const completeAddress = `${officeAddress} ${officeAddress2} ${officeCity} ${officeZip}`;
   return (
      <div
         key={id}
         className={`border-line my-4 h-24 flex rounded-lg cursor-pointer ${isSelect && "text-white bg-blue-800"}`}
         onClick={() => selectHospital(hospital)}
      >
         <div className="flex flex-col justify-center items-center mx-3">
            <Avatar
               src={`${process.env.REACT_APP_PROFILE_URL}/${hospital?.id}_logo?id=${Date.now()}`}
               className="cursor-pointer shadow-md bg-white align-middle"
               radius={55}
               fit={true}
               isHospImg={true}
               bgColor={isSelect ? "#FF567A" : "#043293"}
               name={name}
            />
         </div>
         <div className="text-left flex-1 flex flex-col justify-center">
            <div className="text-sm sm:text-xl font-bold text-ellipsis overflow-hidden max-w-sm">
               <div className="truncate ..."> {name}</div>
               <div className={`text-sm ${isSelect ? "text-white" : "text-[#8A8A8E]"} `}>
                  {completeAddress && completeAddress}
               </div>
            </div>
         </div>
         <div className="pr-2 relative flex flex-col justify-between text-sm font-medium">
            <div
               className={`h-full ml-2 flex items-center justify-between ${isSelect ? "text-white" : "text-[#8A8A8E]"}`}
            >
               <div className="flex items-center justify-center h-full">
                  <img
                     src={isSelect ? infoWhite : info}
                     alt=""
                     className="mr-1 w-6 h-6"
                     onClick={() => selectHospDetails(true)}
                  />
               </div>
            </div>
         </div>
      </div>
   );
}

export default HealthcareFacilityComp;
