import * as types from "../actions/auth.action";

const INITIAL_STATE = {
   token: null,
   usercredentials: null,
   northwelluser: null,
   isPBCSamlLoginDone: false,
   recentSearchPLB: [],
   recentSearchNorth: [],
};

export default function auth(state = INITIAL_STATE, action) {
   switch (action.type) {
      case types.NORTHWELLUSER:
         return {
            ...state,
            northwelluser: action.payload.northwelluserobj,
         };
      case types.USER_CREDENTIALS:
         return {
            ...state,
            userCredentials: action.payload.userObj,
         };
      default:
         return state;
   }
}
