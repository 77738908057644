import { gql } from "@apollo/client";

export const GET_NOTIFICATION_SETTINGS = gql`
   query GetProfile {
      getProfile {
         notificationSettings {
            sms
            email
            push
         }
      }
   }
`;

export const UPDATE_NOTIFICATION_SETTINGS = gql`
   mutation UpdateProfile($user: UserInput) {
      updateProfile(user: $user) {
         user {
            notificationSettings {
               sms
               email
               push
            }
         }
         status {
            message
         }
      }
   }
`;
