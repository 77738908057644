import React, { useEffect, useState } from "react";
import defaultLogo from "assets/img/pbh-logo.png";
import defaultLogoWhite from "assets/img/pbh-logo-white.svg";
import OtpInput from "react-otp-input";
import mail from "images/login/mail.svg";
import google from "images/login/google.svg";
import phone from "images/login/Phone.svg";
import apple from "images/login/apple.svg";
import InputBox from "shared/components/inputBox";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { capitalizeFirstLetter, formatPhoneNumber, getBrowser } from "shared/actions/helpers";
import { useNavigate } from "react-router-dom";
import { googleAuth, appleAuth } from "shared/actions/socialLogin";
import arrow from "images/login/arrow.svg";
import calendarIcon from "images/login/calendar-icon.svg";
import { loginConst, placeholders } from "shared/constants/loginConst";
import SelfOnboardRestricted from "./selfOnboardRestricted/selfOnboardRestricted";
import SelfOnboardSuccess from "./selfOnboardRestricted/successOnboard";
import ChooseHealthcareComp from "./ChooseHealthcareComp";
export const closeButton = {
   closeButton: false,
};

const customStyles = {
   option: (styles, { isSelected }) => {
      return {
         ...styles,
         fontSize: "12px",
         backgroundColor: !isSelected ? "rgba(3, 100, 230, 0.1)" : "white",
         color: "black",
         ":hover": {
            backgroundColor: !isSelected ? "" : "rgba(3, 100, 230, 0.1)",
         },
      };
   },
};

const PatientLogin = ({
   nextStepHandler,
   healthcareTitle,
   healthcareContent,
   step,
   savedPhoneNumber,
   setSavedPhoneNumber,
   fullName,
   savedEmail,
   setSavedEmail,
   setFullName,
   dateOfBirth,
   setDateOfBirth,
   hospitals,
   location,
   setLocation,
   otp,
   setOtp,
   login = false,
   setIsPhone,
   isPhone,
   resendOtp = () => {},
   goBack = () => {},
   btnText = false,
   dateNew = false,
   setDateNew = () => {},
   selectedDoctor,
   setSelectedDoctor,
   className = false,
   selectedHospital,
   setSelectedHospital,
   checkSafariSocialLogin = () => {},
}) => {
   const navigate = useNavigate();
   const [isGifLoaded, setIsGifLoaded] = useState(false);
   useEffect(() => {
      window.addEventListener("keypress", onEnterSubmit);
      return () => {
         window.removeEventListener("keypress", onEnterSubmit);
      };
   }, []);

   const onEnterSubmit = (e) => {
      if (e.key === "Enter" || e.code === "NumpadEnter") {
         document.getElementById("enter-btn-patient") && document.getElementById("enter-btn-patient").click();
      }
   };
   const isStep6 = step === 6;

   const checkIsSafariBrowser = getBrowser() === "Safari";

   return (
      <div
         className={`sm:flex sm:items-center sm:justify-center sm:flex-col w-full sm:py-10 ${
            step === 11 && "relative"
         }`}
      >
         <div className={`${step === 11 && "fixed top-[4%] left-[2%]"}`}>
            {step === 11 && (
               <img
                  src={defaultLogoWhite}
                  alt="playback logo"
                  className={` ${step !== 0 && step !== 11 && "hidden"} sm:block`}
                  width={step === 11 ? 100 : 150}
               />
            )}
            {step !== 11 && (
               <img
                  src={defaultLogo}
                  alt="playback logo"
                  className={` ${step !== 0 && step !== 11 && "hidden"} sm:block`}
                  width={step === 11 ? 100 : 150}
               />
            )}
         </div>
         <div
            className={`${
               step === 11
                  ? "border-none bg-transparent w-full h-full z-[1]"
                  : "bg-white border-20 w-full sm:w-[750px] mt-4 sm:text-center pb-2"
            } "
            `}
         >
            <div className="sm:text-center pt-4 sm:relative">
               {step !== 0 && step !== 11 && (
                  <img
                     src={arrow}
                     onClick={goBack}
                     alt="arrow"
                     className={`hidden sm:block absolute ${
                        login && step === 3 ? "left-1" : "left-3 "
                     }  top-7 ${"sm:left-10 "} sm:top-8 cursor-pointer top-6 left-3`}
                  />
               )}
               <div className="text-2xl sm:text-[32px] font-bold pt-3 sm:pb-4 flex item-center sm:block">
                  {step !== 0 && step !== 11 && (
                     <img
                        src={arrow}
                        onClick={goBack}
                        alt="arrow"
                        className={` sm:hidden mr-2 ${step === 3 && "mt-[-30px]"}`}
                     />
                  )}
                  <div>
                     {step !== 11 && healthcareTitle}
                     {step === 3 && (
                        <div
                           style={{ wordBreak: "break-all" }}
                           className={` sm:hidden inline text-2xl sm:font-head ${
                              isPhone && ""
                           } font-bold mb-20 sm:pl-0 pl-1`}
                        >
                           {formatPhoneNumber(savedPhoneNumber)}
                           {savedEmail}
                        </div>
                     )}
                  </div>
               </div>
               {healthcareContent && (
                  <div
                     className={` ${
                        step === 10 ? " capitalize sm:w-[60%]" : "sm:w-4/5"
                     } text-sm mt-1 sm:mt-0 color-h1 sm:font-sub  w-full  leading-6 m-auto ${
                        step !== 0 && "sm:pl-0 pl-8 "
                     }`}
                  >
                     {healthcareContent}
                  </div>
               )}
               {step === 3 && (
                  <div
                     className={` hidden sm:block text-2xl sm:font-head ${isPhone && ""} font-bold mb-20 sm:pl-0 pl-8`}
                  >
                     {formatPhoneNumber(savedPhoneNumber)}
                     {savedEmail}
                  </div>
               )}
            </div>

            {step === 5 && (
               <div className=" w-[100%] mt-5 mb-11 text-center sm:w-3/4 m-auto pt-4">
                  <InputBox
                     placeholder={placeholders.fullName}
                     type="text"
                     value={fullName}
                     onChange={(e) => {
                        setFullName(e.target.value);
                     }}
                  />
                  <div className="w-full mt-2">
                     <div>
                        <DatePicker
                           onSelect={(e) => {
                              setDateOfBirth(e?.getTime());
                           }}
                           onChange={(e) => {
                              setDateOfBirth(e?.getTime());
                           }}
                           value={dateOfBirth}
                           selected={dateOfBirth}
                           showMonthDropdown={true}
                           showYearDropdown={true}
                           dropdownMode="select"
                           className={` mt-2 p-4 font-small common-input-style`}
                           autoComplete="off"
                           placeholderText={placeholders.dateOfBirth}
                           dateFormat="MM/dd/yyyy"
                           maxDate={new Date()}
                           customInput={
                              <InputMask mask="99/99/9999">{(inputProps) => <input {...inputProps} />}</InputMask>
                           }
                        />
                     </div>
                  </div>
               </div>
            )}

            {step === 2 && (
               <div className=" w-[90%] sm:w-3/5 m-auto pt-4">
                  <Select
                     styles={customStyles}
                     className="w-100"
                     isLoading={hospitals.length === 0}
                     options={hospitals}
                     onChange={(e) => {
                        setLocation(e);
                     }}
                     hideSelectedOptions={false}
                     closeMenuOnSelect={true}
                     value={location}
                  />

                  <div id="map" className="w-full h-64 mb-2 mt-4"></div>
               </div>
            )}
            {step === 3 && (
               <div className="text-center flex flex-col items-center  mb-24 relative w-[325px] sm:w-[56%] m-auto pt-16 sm:pt-0">
                  <OtpInput
                     inputStyle="singlexsFiled w-full sm:singleTextField input-border text-lg focus:outline-0 "
                     numInputs={6}
                     separator={
                        <>
                           <span className="sm:hidden">&nbsp;&nbsp;&nbsp;</span>
                           <span className="hidden sm:block">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </>
                     }
                     otpType="number"
                     onChange={(e) => {
                        setOtp(e);
                     }}
                     value={otp}
                     shouldAutoFocus={true}
                     isInputNum="true"
                  />
                  <div
                     onClick={resendOtp}
                     className="pb-pri font-17 w-full pt-2 cursor-pointer flex items-center justify-end "
                  >
                     {placeholders.resend}
                  </div>
               </div>
            )}

            {step === 0 && (
               <div className="w-[100%] mt-11 mb-11 text-center sm:w-3/4 m-auto">
                  {isPhone ? (
                     <InputBox
                        placeholder={placeholders.mobile}
                        type="number"
                        value={savedPhoneNumber}
                        onChange={(e) => setSavedPhoneNumber(e.target.value)}
                     />
                  ) : (
                     <InputBox
                        placeholder={placeholders.email}
                        type="text"
                        value={savedEmail}
                        onChange={(e) => setSavedEmail(e.target.value)}
                     />
                  )}
               </div>
            )}

            {(step === 4 || isStep6) && (
               <div className="w-[100%] mt-5 mb-11 text-center sm:w-3/4 m-auto">
                  <div className="w-full">
                     <DatePicker
                        onSelect={(e) => {
                           if (isStep6) {
                              setDateNew(e?.getTime());
                           } else {
                              setDateOfBirth(e?.getTime());
                           }
                        }}
                        onChange={(e) => {
                           if (isStep6) {
                              setDateNew(e?.getTime());
                           } else {
                              setDateOfBirth(e?.getTime());
                           }
                        }}
                        value={isStep6 ? dateNew : dateOfBirth}
                        selected={isStep6 ? dateNew : dateOfBirth}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className={` mt-2 p-4 font-small common-input-style`}
                        autoComplete="off"
                        placeholderText={placeholders.dateOfBirth}
                        dateFormat="MM/dd/yyyy"
                        maxDate={new Date()}
                        customInput={
                           <InputMask mask="99/99/9999" value={isStep6 ? dateNew : dateOfBirth}>
                              {(inputProps) => <input {...inputProps} />}
                           </InputMask>
                        }
                     />
                  </div>
               </div>
            )}
            {step === 12 && (
               <ChooseHealthcareComp
                  setSelectedHospital={setSelectedHospital}
                  selectedHospital={selectedHospital}
                  nextStep={nextStepHandler}
               />
            )}
            {step === 1 && (
               <div className=" w-[100%] mt-5 mb-11 text-center sm:w-3/4 m-auto pt-4">
                  <InputBox
                     placeholder={placeholders.fullName}
                     type="text"
                     value={fullName}
                     onChange={(e) => {
                        let properName = capitalizeFirstLetter(e.target.value);
                        setFullName(properName);
                     }}
                  />
                  <InputBox
                     placeholder={placeholders.mobile}
                     type="number"
                     className="mt-3"
                     value={savedPhoneNumber}
                     onChange={(e) => {
                        setSavedPhoneNumber(e.target.value);
                     }}
                  />
                  <InputBox
                     placeholder={placeholders.email}
                     type="text"
                     className="mt-3"
                     value={savedEmail}
                     onChange={(e) => {
                        setSavedEmail(e.target.value);
                     }}
                  />

                  <div className="relative w-full">
                     <div>
                        <DatePicker
                           id="patient-dob"
                           onSelect={(e) => {
                              setDateOfBirth(e?.getTime());
                           }}
                           onChange={(e) => {
                              setDateOfBirth(e?.getTime());
                           }}
                           value={dateOfBirth}
                           selected={dateOfBirth}
                           showMonthDropdown={true}
                           showYearDropdown={true}
                           dropdownMode="select"
                           className={` mt-2 p-4 font-small common-input-style`}
                           autoComplete="off"
                           placeholderText={placeholders.dateOfBirth}
                           dateFormat="MM/dd/yyyy"
                           maxDate={new Date()}
                           customInput={
                              <InputMask mask="99/99/9999">{(inputProps) => <input {...inputProps} />}</InputMask>
                           }
                        />
                     </div>
                     <img
                        className="absolute cursor-pointer"
                        style={{ right: "15px", bottom: "15px" }}
                        src={calendarIcon}
                        alt=""
                        onClick={() =>
                           document.getElementById("patient-dob") && document.getElementById("patient-dob").click()
                        }
                     />
                  </div>
               </div>
            )}
            {step === 6 && (
               <div className="w-full sm:w-[50%] m-auto text-[13px] text-black">
                  {loginConst.patientOnboard.sameDob}
               </div>
            )}

            {step === 10 && (
               <SelfOnboardRestricted
                  selectedHospital={selectedHospital}
                  selectedDoctor={selectedDoctor}
                  setSelectedDoctor={setSelectedDoctor}
                  savedPhoneNumber={savedPhoneNumber}
                  setSavedPhoneNumber={setSavedPhoneNumber}
                  fullName={fullName}
                  setFullName={setFullName}
                  savedEmail={savedEmail}
                  setSavedEmail={setSavedEmail}
                  dateOfBirth={dateOfBirth}
                  setDateOfBirth={setDateOfBirth}
               />
            )}

            {step === 11 && <SelfOnboardSuccess setIsGifLoaded={setIsGifLoaded} nextStepHandler={nextStepHandler} />}
            {step !== 10 && step !== 11 ? (
               <button
                  className={` w-[100%] sm:w-2/4 ${
                     className ? className : "primary-bg text-white "
                  }  rounded-lg p-3  font-17 ${btnText && step !== 10 ? "mt-12" : step !== 10 && "mt-2"} mb-2 ${
                     step !== 0 && step !== 2 && "mb-12"
                  }`}
                  id="enter-btn-patient"
                  onClick={() => nextStepHandler()}
               >
                  {btnText ? btnText : placeholders.next}
               </button>
            ) : (
               step !== 11 && (
                  <button
                     className={` w-[100%] sm:w-2/4 ${
                        className ? className : "primary-bg text-white "
                     }  rounded-lg p-3  font-17  mb-2 `}
                     id="enter-btn-patient"
                     onClick={() => nextStepHandler()}
                  >
                     {btnText ? btnText : placeholders.next}
                  </button>
               )
            )}
            {step === 11 && isGifLoaded && (
               <div
                  className="hidden sm:block font-bold text-base text-[#FF567A] text-center pb-[20px] cursor-pointer pt-4"
                  onClick={() => nextStepHandler()}
               >
                  {placeholders.anotherAccount}
               </div>
            )}
            {step === 2 && (
               <div
                  onClick={() => {
                     nextStepHandler(true);
                  }}
                  className=" w-[90%] sm:w-3/5 m-auto pt-2 pb-4 cursor-pointer  pb-primary underline font-medium text-lg"
               >
                  {placeholders.skip}
               </div>
            )}

            {step === 0 && (
               <div className="text-center mt-3">
                  <h4 className="text-black font-medium py-4">
                     or{login ? placeholders.signin : placeholders.createLogin} with
                  </h4>
                  <div className="flex justify-center">
                     <img
                        src={!isPhone ? phone : mail}
                        alt="mail-login"
                        className="cursor-pointer"
                        onClick={setIsPhone}
                     />
                     <img
                        src={apple}
                        onClick={() => {
                           if (checkIsSafariBrowser) checkSafariSocialLogin(false);
                           else appleAuth();
                        }}
                        alt="apple-login"
                        className="cursor-pointer"
                     />
                     <img
                        src={google}
                        onClick={() => {
                           if (checkIsSafariBrowser) checkSafariSocialLogin();
                           else googleAuth();
                        }}
                        alt="google-login"
                        className="cursor-pointer "
                     />
                  </div>
                  <h4 className="shade-color font-15 pt-4">
                     {login ? placeholders.noAccount : placeholders.existingAccount}
                  </h4>
                  <button
                     onClick={() => {
                        if (login) {
                           navigate("/sign-up");
                        } else {
                           navigate("/");
                        }
                     }}
                     className=" w-[90%] font-bold bg-white p-2 blue-cus mb-6 sm:w-2/4 text-lg"
                  >
                     {login ? placeholders.createContent : placeholders.signIn}
                  </button>
               </div>
            )}
         </div>
         {step === 11 && (
            <div>
               <div className={`${step === 11 && "patient-circle-one"} sm:top-[15%] sm:left-[24%]`} />
               <div className={`${step === 11 && "patient-circle-two"} sm:top-[30%] sm:right-[14%]`} />
               <div className={`${step === 11 && "patient-circle-three"} sm:bottom-[0%] sm:left-[1%]`} />
            </div>
         )}
      </div>
   );
};

export default PatientLogin;
