import { GET_FAMILY_FRIENDS_DATA } from "modules/shareFamilyFriends/actions/familyActions";
import { ShowAlert } from "shared/actions/alert";
import { fetchQuery } from "shared/actions/fetchQueryAction";
import { errorToDisplay } from "shared/actions/helpers";

export const getFilteredResults = (searchKey, shareTeamList) => {
   const term = searchKey.toLowerCase();
   var arr = shareTeamList.filter((f) => {
      const { careMember } = f;
      const fname = careMember?.name?.fullName ? careMember?.name?.fullName?.toLowerCase() : "";
      const email = careMember?.contactInformation?.email ? careMember?.contactInformation?.email?.toLowerCase() : "";
      const type = f?.relationship ? f?.relationship?.toLowerCase() : "";
      return fname.includes(term) || email.includes(term) || type.includes(term);
   });
   return arr;
};

export const getSharedPatientList = (id, callBack) => {
   const payload = {
      user: {
         id,
      },
      type: {
         isActive: true,
         type: "family",
      },
   };
   fetchQuery(
      GET_FAMILY_FRIENDS_DATA,
      payload,
      (response) => {
         const data = response?.data?.getCareTeam || [];
         callBack(data);
      },
      (error) => {
         const errMessage = errorToDisplay(error);
         ShowAlert(errMessage, "error");
         callBack([]);
      }
   );
};
