import React, { useEffect } from "react";
import captureBtn from "images/settings/capture.svg";
import crossBtn from "images/settings/cross.svg";
import Button from "shared/components/button";
import { settingsConst } from "shared/constants/settingsConst";
export default function WebCam({ clickImage, getImage, close }) {
   useEffect(() => {
      startCamera();
      return () => {
         window.localStream && window.localStream.getVideoTracks()[0].stop();
      };
   }, []);

   const startCamera = async () => {
      let video = document.querySelector("#video");
      let click_button = document.querySelector("#click-photo");
      let canvas = document.querySelector("#canvas");

      let stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
      video.srcObject = stream;
      window.localStream = stream;

      click_button.addEventListener("click", function () {
         canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
         let file = null;
         document.querySelector("#canvas").toBlob(function (blob) {
            file = new File([blob], "test.jpg", { type: "image/jpeg" });
            clickImage(file);
            stream.getTracks().forEach(function (track) {
               track.stop();
            });
         }, "image/jpeg");
      });
   };
   return (
      <div>
         <div className=" w-full relative bg-black sm:h-[450px] sm:w-[600px]">
            <div className="rounded-lg absolute sm:right-[15px] right-[15px]" style={{ top: 14, zIndex: "10" }}>
               <img id="click-photo-new" src={crossBtn} alt="cross" className="cursor-pointer" onClick={close} />
            </div>
            <video
               style={{ backgroundColor: "black" }}
               id="video"
               width="600"
               height="450"
               autoPlay
               className="-mt-[90px] sm:-mt-[30px]"
            ></video>

            <img
               id="click-photo"
               src={captureBtn}
               alt="capture"
               className="absolute cursor-pointer"
               style={{ bottom: 10, left: "45.99%" }}
            />
         </div>
         <div className="flex items-center justify-center flex-col">
            <div className="font-17 text-black pb-[5px] pt-[5px]">or</div>
            <Button
               onClick={() => {
                  document.getElementById("input-photo-upload") &&
                     document.getElementById("input-photo-upload").click();
               }}
               className="w-[140px] h-[40px]"
               id="click-photo-btn"
            >
               {settingsConst.upload}
            </Button>
            <input
               id="input-photo-upload"
               style={{ width: 0, height: 0, visibility: "hidden" }}
               type="file"
               onChange={(e) => getImage(e)}
               accept="image/*"
            />
         </div>

         <br />
         <canvas id="canvas" style={{ visibility: "hidden", position: "absolute" }} width="320" height="240"></canvas>
      </div>
   );
}
