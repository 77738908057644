import React from "react";
import PatientComponent from "modules/patientOnboard/components/PatientComponent";

import { bindActionCreators } from "redux";
import * as actions from "redux/actions/auth.action";
import { connect } from "react-redux";

const PatientContainer = (props) => {
   return <PatientComponent {...props} />;
};

const mapStateToProps = (state) => {
   return {
      auth: state.auth,
   };
};
const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         savenorthwelluserobj: actions.savenorthwelluserobj,
         saveusercredentials: actions.saveusercredentials,
      },
      dispatch
   );
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientContainer);
