import React from "react";
import Navbar from "modules/navbar/component/navBarComponent";
import { connect } from "react-redux";

const NavbarCont = (props) => {
   return <Navbar {...props} />;
};

const mapStateToProps = (state) => {
   return {
      auth: state.auth,
      darkly: state.darkly,
   };
};

export default connect(mapStateToProps, null)(NavbarCont);
