import React from "react";
import loader from "assets/gif/loader.gif";
var LoadingIndicator = () => {
   return (
      <div className="flex items-center justify-center h-100 w-100">
         <img alt="loading" src={loader} className="w-16" />
      </div>
   );
};
export default LoadingIndicator;
