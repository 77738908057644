import React from "react";

function ReferralDetailsView(props) {
   const { referralDetails } = props;
   let patientName = referralDetails.patientName ? referralDetails.patientName : "Patient";

   let physicianArray = referralDetails.providers?.map((provider) => {
      return provider.firstname + " " + provider.lastname;
   });

   let finalPhysicianString = "";

   if (physicianArray?.length === 1) {
      finalPhysicianString = physicianArray?.join(", ");
   } else if (physicianArray?.length === 2) {
      finalPhysicianString = physicianArray[0] + " and + 1 other physician";
   } else {
      finalPhysicianString = physicianArray?.[0] + " and +" + [physicianArray?.length - 1] + " other physicians";
   }
   return (
      <div className="bg-white round-border-l  p-4 w-[90%] sm:w-[35%] rounded-lg">
         <div className="text-[20px] text-[#17182d] font-bold">
            {`${referralDetails.addedbyName} has referred ${patientName} to ${referralDetails.toHealthSystem}`}
         </div>
         <div className="text-[18px] text-[#17182d] font-semibold py-2">{finalPhysicianString}</div>
         {referralDetails.messageContent && (
            <div className="referral-message-div w-100">
               <div className="message-heading text-extra-bold text-normal text-black2 mt-4">Note</div>
               <div className="message-description text-black2 text-small text-fit">
                  {referralDetails.messageContent}
               </div>
            </div>
         )}
         {referralDetails.video && (
            <div className="referral-video-div w-100">
               <div className="message-heading text-extra-bold text-normal text-black2 mt-4">Video Message</div>
               <div className="message-description">
                  <video
                     src={referralDetails.video}
                     style={{ height: "160px", width: "100%" }}
                     controls
                     disablePictureInPicture
                     controlsList="nodownload"
                  />
               </div>
            </div>
         )}
      </div>
   );
}

export default ReferralDetailsView;
