import React from "react";
import Banner from "modules/banner/Component/banner";
import { connect } from "react-redux";

const BannerCont = (props) => {
   return <Banner {...props} />;
};

const mapStateToProps = (state) => {
   return {
      auth: state.auth,
   };
};

export default connect(mapStateToProps, null)(BannerCont);
