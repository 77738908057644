import React, { memo, useState, useEffect, useMemo } from "react";
import audioIcon from "../../../images/newimages/recording-icons/visualizer.svg";
import defaultThumb from "images/newimages/default-thumb.svg";
import playIcon from "images/dashboard/playIcon.svg";
import {
   callMobileNumber,
   isIOS,
   isNumber,
   isValidEmail,
   isValidUrl,
   openLink,
   sendMail,
} from "shared/actions/helpers";
import { useQuery } from "@apollo/client";
import { GET_THUMBNAIL_SIGNED_URL, UPDATE_ITEM_VIEWED_STATUS } from "modules/home/constants/contentConstants";
import { fetchMutation } from "shared/actions/fetchMutationAction";
export const Video = (props) => {
   return (
      <video
         style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
            transform: props.mirrored ? "rotateY(180deg)" : null,
            WebkitTransform: props.mirrored ? "rotateY(180deg)" : null,
            MozTransform: props.mirrored ? "rotateY(180deg)" : null,
            backgroundColor: "black",
         }}
         controlsList="nodownload"
         disablePictureInPicture
         {...props}
      >
         {props.children}
      </video>
   );
};

export const AudioPlaybackView = (props) => (
   <audio controls className="shadow round-border-l" controlsList="nodownload" {...props} />
);

const PostView = memo(
   (props) => {
      const { data, className, isPreview = false, PostCard, user, isLibraryFile = false } = props;
      const [signedUrl, setsignedUrl] = useState(null);
      const [thumbnailUrl, setThumbnailUrl] = useState(defaultThumb);
      const skip = data?.type === "text" || data?.type === "item" || data?.type === "referral" ? true : false;
      const ftype = data?.fileType?.split("/")[0] || data?.type;

      useEffect(() => {
         if (!isPreview) return;
         fetchMutation(UPDATE_ITEM_VIEWED_STATUS, {
            options: {
               eventName: "itemViewed",
               itemId: data?.id,
            },
         });
      }, []);

      useQuery(GET_THUMBNAIL_SIGNED_URL, {
         skip,
         variables: {
            content: {
               id: data.id,
               description: "",
            },
            thumbnail: true,
         },
         onCompleted(result) {
            setThumbnailUrl(result.getSignedURL?.thumbnailUrl);
            setsignedUrl(result.getSignedURL?.url);
         },
      });

      const getMediaURL = useMemo(() => {
         if (isPreview && signedUrl) {
            return signedUrl;
         } else {
            if (data?.type === "referral")
               return `${process.env.REACT_APP_PROFILE_URL}/${user?.enterpriseId}_logo?id=${Date.now()}`;
            else if (!data?.hasThumbnail) return defaultThumb;
            return thumbnailUrl;
         }
      }, [data?.thumbnail, signedUrl, thumbnailUrl]);

      const addDefaultSrc = (e) => {
         e.target.src = defaultThumb;
      };

      const isAudio = ftype?.includes("audio") || ftype === "audio";
      const isItem = data?.type === "item" || data?.type === "text";
      const isImage = ftype?.includes("image") || ftype === "image";
      const isVideo = ftype === "video" || ftype?.includes("video");
      const isApplication = ftype?.includes("application");
      const showPlayIcon = (isAudio || isVideo) && isPreview === false;
      if (isItem) {
         return (
            <div
               className={`w-100  text-large ${
                  isPreview ? "overflow-x-auto h-[480px] pt-6" : "truncate ... pt-7 h-[220px]"
               } `}
            >
               <div className={`${PostCard && "ml-4"}`}>
                  {
                     <div className="flex items-center flex-wrap">
                        {data?.title.split(" ").map((s) => {
                           const isNew = s === "/n";
                           const isValidURI = isValidUrl(s.replace("/\n/gi", ""));
                           const isEmail = isValidEmail(s.replace("\n", ""));
                           const isColoured = isNumber(s) || isValidURI || isEmail;
                           return (
                              <div
                                 onClick={() => {
                                    if (isColoured && isPreview) {
                                       if (isEmail) {
                                          sendMail(s);
                                       }
                                       if (isNumber(s)) {
                                          callMobileNumber(s);
                                       }
                                       if (isValidURI) {
                                          openLink(s);
                                       }
                                    }
                                 }}
                                 className={`  ${isPreview && " z-[100]"}  ${isNew ? "whitespace-pre" : "ml-2"}  ${
                                    isColoured && "div-mention-content"
                                 } `}
                              >
                                 {s + " "}
                              </div>
                           );
                        })}
                     </div>
                  }
                  <div className="whitespace-pre-line">{data?.subTitle}</div>
               </div>
            </div>
         );
      } else if (isImage || !isPreview) {
         let fitType = "cover";
         return (
            <div className={data?.type === "referral" ? "h-100" : " h-full w-full relative  "}>
               {data?.type === "referral" ? (
                  <div className={`${PostCard && "ml-4"} pt-8 px-2 `}>
                     <div className="para-one">{data.titleDescription}</div>
                     <div className="para-two">
                        {data.subTitleDescription}
                        <br />
                     </div>

                     <div className="para-two">see more...</div>
                  </div>
               ) : (
                  <>
                     {isAudio ? null : (
                        <div
                           className={`absolute w-full ${ftype === "application" ? " z-0 h-full " : "h-full"}  ${
                              ftype === "audio" ? "hidden" : "z-10"
                           }`}
                        >
                           <img
                              style={{ objectFit: isPreview ? "contain" : fitType }}
                              alt="thumbnails"
                              className="h-full w-full rounded-t-2xl"
                              src={isPreview ? getMediaURL : thumbnailUrl}
                              onError={(e) => {
                                 e.target.src = defaultThumb;
                                 e.target.style.objectFit = "cover";
                                 e.target.style.maxHeight = "100%";
                                 e.target.style.maxWidth = "100%";
                              }}
                           />
                        </div>
                     )}
                  </>
               )}
               {showPlayIcon && (
                  <img
                     src={playIcon}
                     alt="play-icon"
                     className="absolute h-[50px] w-[50px] top-[45%] left-[45%] z-[60]"
                  />
               )}
               {isAudio ? (
                  <div className="absolute w-full h-full">
                     <img
                        alt="audio"
                        style={
                           !isPreview
                              ? { backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2))" }
                              : {}
                        }
                        className=" bg-light-blue h-full w-full rounded-t-2xl "
                        src={audioIcon}
                     />
                  </div>
               ) : null}
            </div>
         );
      } else if (isVideo) {
         return <Video width="100%" height="100%" controls src={getMediaURL} autoPlay={isPreview} />;
      } else if (isApplication) {
         return signedUrl ? (
            isIOS() ? (
               <div className="flex flex-col items-center justify-center bg-slate-900 w-full h-full">
                  <div className="text-white truncate max-w-xs">{data.title}</div>
                  <button
                     onClick={() => (window.location.href = getMediaURL)}
                     className="primary-bg text-white text-sm font-semibold w-[90%] p-2 rounded-md  w-[100%] mt-2 sm:mt-0 sm:w-[120px] sm:ml-3 h-[50px]"
                  >
                     Open
                  </button>
               </div>
            ) : (
               <div className="w-full h-full">
                  <iframe
                     className="iframe-pdf"
                     title="web-frame"
                     src={`${getMediaURL}#zoom=30&scrollbar=0&#toolbar=0&navpanes=0`}
                     onContextMenu={(e) => e.preventDefault()}
                     style={{ zIndex: "9" }}
                  />
               </div>
            )
         ) : (
            <div className="flex animate-pulse items-center">
               <div className="sm:w-[600px] grow bg-gray-200 sm:h-[470px] rounded-md  "></div>
            </div>
         );
      } else if (isAudio) {
         return <AudioPlaybackView src={getMediaURL} isPreview={isPreview} />;
      } else {
         return (
            <img
               className={`w-100 ${className}`}
               src={getMediaURL}
               onError={addDefaultSrc}
               width="100%"
               height="100%"
               alt=""
               style={{
                  maxWidth: isLibraryFile ? "" : "1037.71px", //225px
                  maxHeight: isLibraryFile ? "" : "461.86px", //125px
                  minWidth: isLibraryFile ? "" : "225px",
                  minHeight: isLibraryFile ? "" : "125px",
                  objectFit: "contain",
               }}
            />
         );
      }
   },
   (prev, next) => prev.type === next.type
);

export default PostView;
