import React from "react";
import Select from "react-select";
const customStyles = {
   option: (styles) => {
      return {
         ...styles,
         display: "flex",
         fontSize: "12px",
         color: "#8c8c8c;",
         backgroundColor: "white",
         ":hover": {
            backgroundColor: "rgba(3, 100, 230, 0.1)",
         },
      };
   },
   control: (base) => ({
      ...base,
      border: "1px solid #e0e0e0",
      boxShadow: "none",
      borderRadius: "8px",
      fontFamily: "sfProTextRegular",
      padding: 6,
      "&:hover": {
         border: "1px solid #e0e0e0",
      },
   }),
   container: (defaultStyles) => {
      return {
         ...defaultStyles,
         textAlign: "left",
         color: "#8c8c8c;",
      };
   },
   singleValue: (defaultStyles) => {
      return {
         ...defaultStyles,
         color: "#000000;",
      };
   },

   placeholder: (defaultStyles) => {
      return {
         ...defaultStyles,
         color: "#8c8c8c;",
         display: "flex",
      };
   },
   indicatorSeparator: (defaultStyles) => {
      return {
         ...defaultStyles,
         display: "none",
      };
   },
};

export default function SelectEfficient({ options = [], value = "", onChange = () => {}, placeholder }) {
   return (
      <Select
         options={options}
         isClearable
         placeholder={placeholder}
         value={value}
         onChange={onChange}
         styles={customStyles}
      />
   );
}
