import React, { useState, useEffect } from "react";
import SearchBox from "shared/components/searchBox/SearchBox";
import HealthcareFacilityComp from "./HealthcareFacilityComp";
import { socketActions, socketSubActions } from "socket/Websocket";
import SkeletonLoader from "shared/components/SkeletonLoader";
import ModalPopup from "shared/components/modal/modal";
import HospitalDetailsComp from "./HospitalDetailsComp";
import noResultsImage from "images/common/no-results-image.svg";
import EmptyStateComp from "shared/components/emptyStates/emptyState";
import { emptyStatesText } from "shared/constants/const";

function ChooseHealthcareComp(props) {
   const { selectedHospital, setSelectedHospital, nextStep } = props;
   const [hospitals, setHospitals] = useState([]);
   const [hospLoading, setHospLoading] = useState(true);
   const [openHospDetails, setOpenHospDetails] = useState(false);
   const [searchKey, setSearchKey] = useState("");
   useEffect(() => {
      gethospitals();
   }, []);

   const gethospitals = () => {
      setHospLoading(true);
      getHospitals();
   };

   const getHospitals = () => {
      const obj = {
         action: socketActions.onboarding,
         subAction: socketSubActions.getHospitals,
      };
      window.socket.send(obj, (resultStatus) => {
         if (resultStatus?.settings?.status === 1) {
            let data = [];
            data = resultStatus?.hospitals?.map((hosp, i) => hosp) || [];
            setHospitals(data);
            setHospLoading(false);
         } else {
            setHospLoading(false);
         }
      });
   };

   const changeSearchQuery = (text) => {
      setSearchKey(text);
   };

   const hospList = hospitals.filter((hospital) => {
      return hospital?.name?.toLowerCase().includes(searchKey.toLowerCase());
   });

   return (
      <div className="sm:flex sm:items-center sm:justify-center sm:flex-col w-full">
         <div className=" bg-white w-full sm:w-[750px] border-20 mt-4 sm:text-center pb-2">
            <div className="w-[90%] sm:w-[70%] m-auto">
               <div className="pt-4">
                  <SearchBox placeholder="Hospital" className="bg-white" text={searchKey} setText={changeSearchQuery} />
                  <div className="h-96 overflow-y-auto healthcare-facility pl-1">
                     {hospLoading ? (
                        <div className="h-24">
                           <SkeletonLoader arrayLength={4} />
                        </div>
                     ) : hospList.length > 0 ? (
                        hospList.map((hospital, i) => {
                           return (
                              <HealthcareFacilityComp
                                 hospital={hospital}
                                 selectHospital={setSelectedHospital}
                                 selectHospDetails={setOpenHospDetails}
                                 selectedHospital={selectedHospital}
                                 id={hospital?.id || i}
                              />
                           );
                        })
                     ) : (
                        <div className="flex flex-col h-full items-center justify-center">
                           <EmptyStateComp src={noResultsImage} headerText={emptyStatesText.emptyResults} />
                        </div>
                     )}
                  </div>
               </div>
            </div>
         </div>
         {openHospDetails && (
            <ModalPopup onModalTapped={() => setOpenHospDetails(false)} className="rounded-lg">
               <HospitalDetailsComp
                  close={() => setOpenHospDetails(false)}
                  hospitalDetails={selectedHospital}
                  nextStep={nextStep}
               />
            </ModalPopup>
         )}
      </div>
   );
}

export default ChooseHealthcareComp;
