import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

export async function getRelationshipFirebase(callBack = () => {}) {
   firebase
      .firestore()
      .collection("AppText")
      .doc("Relationship")
      .get()
      .then((doc) => {
         if (doc.exists) {
            callBack(doc.data());
         } else {
            callBack([]);
         }
      });

   return [];
}
