export const sharedPatientConst = {
   dob: "DOB:",
   mob: "Mobile:",
   email: "Email:",
};
export const options = [
   { text: "Activity", id: 0, link: "/" },
   { text: "Care Team", id: 1, link: "/care-team" },
   { text: "Share", id: 2, link: "/sharing" },
];

export const LIMIT = 20;
