import React, { useState } from "react";
import { textLabels } from "shared/constants/const";
import TableCareTeam from "./tableCareTeam";
import GetDirections from "./directionsGql";
import Modal from "shared/components/modal/modal";
import { getFilteredResults } from "modules/sharedPatientDetails/action/sharedPatientAction";
import SearchField from "modules/sharedPatientDetails/Component/tabs/searchField";

function CareTeam(props) {
   const { careTeam, loading, isShared } = props;
   const [searchKey, setSearchKey] = useState("");
   const [showDirection, setShowDirection] = useState(false);
   const [eachCare, setEachCare] = useState(null);
   const hide = () => {
      setShowDirection(false);
   };
   return (
      <div className={`${!isShared && "sm:mt-5 pl-2"}  `}>
         <div className={` flex items-center justify-between sm:mb-4 `}>
            {!isShared && <div className=" hidden sm:block text-2xl font-normal">{textLabels.careTeam}</div>}
            <div className={`${!isShared ? "hidden sm:inline" : "w-full"} `}>
               <SearchField searchKey={searchKey} setSearchKey={setSearchKey} />
            </div>
         </div>
         <div className={`w-full ${isShared ? "pt-2" : "pt-3"} mt-1 sm:mt-0`}>
            <TableCareTeam
               isShared={isShared}
               members={getFilteredResults(searchKey, careTeam)}
               isLoading={loading}
               openModal={(data) => {
                  setShowDirection(true);
                  setEachCare(data);
               }}
            />
         </div>
         {showDirection && (
            <Modal onModalTapped={hide}>
               <GetDirections eachCare={eachCare} onModalTapped={hide} />
            </Modal>
         )}
      </div>
   );
}

export default CareTeam;
