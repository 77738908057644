import React, { useState } from "react";
import cross from "images/common/cross.svg";
import { textConst } from "shared/constants/const";
import { UPDATE_PROFILE_IMG } from "actions/settings/settingsAction";
import WebCam from "shared/components/webcam/webCam";
import { ShowAlert, showSwal } from "shared/actions/alert";
import { settingsConst } from "shared/constants/settingsConst";
import { errorToDisplay } from "shared/actions/helpers";
import { useMutation } from "@apollo/client";
import { uploadmedia } from "actions";
import ImageCropper from "shared/components/ImageCropper/ImageCropper";
import ShowImage from "./showImage";

export default function EditImage({ onModalTapped, name, imageAvailable, imageUrl, user, showDP, setShowDP }) {
   const [state, setState] = useState({
      profilePic: null,
      uploading: false,
   });
   const [croppedImage, setCroppedImage] = useState(null);

   const [imagePersist, setImagePersist] = useState(imageAvailable);
   const [loading, setLoading] = useState(false);

   const uploadedFile = state.profilePic?.file;
   const patientId = user?.id;

   const [updateProfileImage] = useMutation(UPDATE_PROFILE_IMG, {
      onCompleted(data) {
         const signedUrl = data?.updateProfileImage?.url;
         let progBar = document.getElementById("bar-tail");
         uploadmedia(signedUrl, croppedImage?.file, (progress) => {
            let prog = parseInt((progress / uploadedFile.size) * 100);
            let text = prog.toFixed(2);
            if (progBar) {
               progBar.value = text;
            }
         })
            .then((res) => {
               ShowAlert(settingsConst.media);
               setTimeout(() => {
                  window.location.href = window.location.href;
               }, 1000);
               setImagePersist(true);
            })
            .catch((err) => {
               ShowAlert(err.message, "error");
            });
      },
      onError(err) {
         const errMessage = errorToDisplay(err);
         ShowAlert(errMessage, "error");
         setLoading(false);
      },
   });

   const [deleteProfileImage] = useMutation(UPDATE_PROFILE_IMG, {
      onCompleted(data) {
         ShowAlert(data?.updateProfileImage?.message);
         setTimeout(() => {
            window.location.href = window.location.href;
         }, 1000);
      },
      onError(err) {
         const errMessage = errorToDisplay(err);
         ShowAlert(errMessage, "error");
         setLoading(false);
      },
   });

   const photoEdit = (edit = false, profileImg = null) => {
      if (profileImg !== null) {
         setCroppedImage(profileImg);
         setState({
            ...state,
            uploading: true,
         });
      }
      const payload = {
         variables: {
            updateProfileImageId: patientId,
            operationType: edit ? "write" : "delete",
         },
      };
      if (edit) {
         setLoading(true);
         if (uploadedFile === null) {
            ShowAlert(settingsConst.selectFile, textConst.error);
            return;
         } else updateProfileImage(payload);
      } else {
         showSwal(settingsConst.capsDel, settingsConst.delPhoto, () => deleteProfileImage(payload));
      }
   };

   const getImage = (e) => {
      if (e && e.target.files && e.target.files[0]) {
         setShowDP(false);
         let reader = new FileReader();
         let file = e.target.files[0];
         let url = URL.createObjectURL(file);
         reader.onload = (fileres) => {
            setState({
               ...state,
               profilePic: {
                  file: file,
                  path: url,
               },
            });
            setImagePersist(true);
         };
         reader.readAsDataURL(e.target.files[0]);
      } else {
         setState({ ...state, profilePic: null });
      }
   };

   const getImageCamera = (e) => {
      if (e) {
         let reader = new FileReader();
         let file = e;
         let url = URL.createObjectURL(file);

         reader.onload = (fileres) => {
            setState({
               ...state,
               profilePic: {
                  file: file,
                  path: url,
               },
            });
            setImagePersist(true);
         };
         reader.readAsDataURL(e);
      } else {
         setState({ ...state, profilePic: null });
      }
   };

   const isPhotoSelected = state.profilePic?.path;
   const textToShow = !isPhotoSelected ? "Edit" : "Looking good!";
   const width = imagePersist ? "sm:w-[520px]" : "sm:w-[600px]";
   const height = imagePersist && isPhotoSelected ? "h-[480px]" : imagePersist ? "sm:h-[355px]" : "sm:h-[560px]";
   return (
      <div
         className={`bg-white flex flex-col justify-center rounded-lg ${height} ${width} min-w-[100%] sm:min-w-[400px] sm:min-h-[355px] ${
            isPhotoSelected ? "min-h-[550px]" : "min-h-[430px]"
         } relative ${imagePersist && "pb-5"}`}
      >
         <>
            {imagePersist && (
               <div
                  className={`mb-3 flex justify-center w-full absolute ${
                     isPhotoSelected ? "top-4" : "top-3"
                  } font-semibold text-xl `}
               >
                  {textToShow}
               </div>
            )}
            <div>
               <img
                  onClick={onModalTapped}
                  src={cross}
                  alt="cross-btn"
                  className="absolute top-5 cursor-pointer sm:right-5 right-6"
               />
            </div>
         </>
         {imagePersist ? (
            <div className="w-full h-64 flex justify-center flex-col" style={{ width: "70% !important" }}>
               {showDP && <ShowImage srcImg={croppedImage?.path || imageUrl} isPhotoSelected={isPhotoSelected} />}
               {loading ? (
                  <div className="w-[91%] pt-5 m-auto">
                     <progress style={{ width: "100%", height: "10px" }} id="bar-tail" max="100" />
                  </div>
               ) : (
                  <div className="">
                     <ImageCropper
                        profilePicPath={state.profilePic?.path || imageUrl}
                        croppedImage={croppedImage}
                        fileName={state.profilePic?.file?.name}
                        isPhotoSelected={isPhotoSelected}
                        initialState={() =>
                           setState({
                              ...state,
                              profilePic: {
                                 file: null,
                                 path: null,
                              },
                           })
                        }
                        setImagePersist={() => setImagePersist(false)}
                        userId={patientId}
                        deleteProfilePhoto={photoEdit}
                        updateProfilePhoto={photoEdit}
                        setShowDP={setShowDP}
                     />
                  </div>
               )}
            </div>
         ) : (
            <WebCam
               close={onModalTapped}
               clickImage={(file) => {
                  setShowDP(false);
                  getImageCamera(file);
               }}
               getImage={getImage}
               setShowDP={setShowDP}
            />
         )}
      </div>
   );
}
