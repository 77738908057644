import React, { memo } from "react";
import { v4 as uuid } from "uuid";
import success from "assets/img/success-icon.svg";
import error from "assets/img/error-icon.svg";

let style = {
   color: "#17182d",
   //    background: "rgba(242, 242, 242, 1)",
   //    boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.25)",
   width: "100%",
   minHeight: "100%",
   fontWeight: "500",
};

export const defaultToastProps = {
   position: "top-center",
   autoClose: 3000,
   hideProgressBar: true,
   closeOnClick: true,
   closeButton: false,
   pauseOnHover: true,
   toastId: uuid(),
};

function ToastView(props) {
   const { type = "success", text = "" } = props;

   return (
      <div style={style} className="rounded-lg flex items-center justify-start w-full h-100">
         <img className="ml-4 mr-3" src={type === "success" ? success : error} alt="" />
         <div className={`h3 m-0 `}>{text}</div>
      </div>
   );
}

export default memo(ToastView);
