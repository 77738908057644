import React, { useMemo, useRef, useEffect, useState } from "react";
import { calculateHospInitials } from "shared/actions/helpers";

const Avatar = (props) => {
   const {
      radius = 30,
      name = "",
      src = false,
      bgColor,
      initialsApi = false,
      className,
      gotImage = () => {},
      isHospImg = false,
      showError = () => {},
      isDetails = false,
      fromEditImg = false,
   } = props;
   const imageRef = useRef();
   const [imageLoaded, setImageloaded] = useState(false);

   const initials = useMemo(() => {
      if (isHospImg) {
         return calculateHospInitials(name);
      } else {
         let nameArray = name.split(" ");
         if (nameArray && nameArray.length > 1)
            return nameArray[0][0]?.toUpperCase() + (nameArray[nameArray.length - 1][0]?.toUpperCase() || "");
         return nameArray[0][0]?.toUpperCase() || "";
      }
   }, [name]);
   useEffect(() => {
      if (imageRef.current) {
         imageRef.current.style.opacity = "1";
         if (!isHospImg) {
            imageRef.current.style.display = "initial";
         }
      }
   }, [name, src]);
   return (
      <div style={{ width: radius, height: radius }}>
         <div
            style={{ backgroundColor: imageLoaded ? "white" : bgColor, borderRadius: "50%" }}
            className={`w-full h-full flex items-center justify-center relative ${className}`}
         >
            {src && (
               <img
                  style={{
                     width: radius,
                     height: radius,
                     zIndex: 1,
                     objectFit: "contain",
                  }}
                  className="absolute w-full h-full rounded-[120px]"
                  onError={(e) => {
                     showError();
                     e.target.style.opacity = 1;

                     if (isHospImg || fromEditImg) {
                        e.target.style.display = "none";
                        e.target.style.width = "40px";
                        e.target.style.height = "40px";
                        e.target.style.objectFit = "contain";
                     } else {
                        e.target.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
                     }
                  }}
                  onLoad={(e) => {
                     if (isHospImg) {
                        setImageloaded(true);
                     }
                     gotImage();
                  }}
                  src={src}
                  alt=""
                  ref={imageRef}
               />
            )}
            <h3 className={`text-xs ${isDetails ? "text-3xl" : "sm:font-17"} text-white`}>
               {initialsApi ? initialsApi : initials}
            </h3>
         </div>
      </div>
   );
};

export default Avatar;
