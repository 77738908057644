import React from "react";
import { notifications } from "shared/constants/notificationsConst";
import EachRow from "./eachRow";
export default function ChangeNotificationsSettings({
   sms,
   email,
   pNotif,
   setSms,
   setEmail,
   setPNotif,
   updatePatientNotifications,
}) {
   return (
      <div className="sm:w-[600px] m-auto ">
         <div className="font-semibold text-[22px] text-[ #17182D] py-[25px]">{notifications.notifications}</div>
         <EachRow
            text={notifications.sms}
            value={sms}
            onChange={(val) => {
               updatePatientNotifications(val, email, pNotif);
               setSms(val);
            }}
         />
         <EachRow
            text={notifications.email}
            value={email}
            onChange={(val) => {
               updatePatientNotifications(sms, val, pNotif);
               setEmail(val);
            }}
         />
         <EachRow
            text={notifications.notif}
            value={pNotif}
            onChange={(val) => {
               updatePatientNotifications(sms, email, val);
               setPNotif(val);
            }}
         />
      </div>
   );
}
