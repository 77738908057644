import React, { useState, useEffect } from "react";
import successStart from "images/login/success-start.gif";
import successEnd from "images/login/success-end.png";
import { placeholders } from "shared/constants/loginConst";
import successStartAnimation from "images/login/success-start.json";
import Lottie from "lottie-react";

export default function SelfOnboardSuccess(props) {
   const { setIsGifLoaded, nextStepHandler } = props;
   const [gif, setGif] = useState(successStart);
   const [isFinal, setIsFinal] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setGif(successEnd);
         setIsFinal(true);
         setIsGifLoaded(true);
      }, 1000);
   }, [gif]);
   return (
      <div className="flex align-middle justify-center flex-col">
         <div className=" w-[100%] h-[100%]  text-center sm:w-3/4 m-auto pt-4">
            {
               <div className={`small-frame flex items-center justify-center pt-14`}>
                  {!isFinal && <Lottie animationData={successStartAnimation} loop={false} />}
               </div>
            }
            {isFinal && (
               <div>
                  <div className="text-white text-[22px] font-semibold w-full sm:w-[270px] m-auto">
                     <img
                        src={successEnd}
                        alt="done-onboard"
                        className={`text-center m-auto`}
                        style={{
                           width: isFinal ? "150px" : "300px",
                           height: isFinal ? "150px" : "300px",
                        }}
                     />
                     {placeholders.requestSubmitted}
                  </div>
               </div>
            )}
         </div>
         {isFinal && (
            <div
               className=" sm:hidden font-bold text-base text-[#FF567A] text-center pb-[20px] cursor-pointer pt-4"
               onClick={() => nextStepHandler()}
            >
               {placeholders.anotherAccount}
            </div>
         )}
      </div>
   );
}
