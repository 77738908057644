import React from "react";
import "index.css";
import "css/datePicker.css";
import { connect } from "react-redux";
import RouterComp from "modules/router/component/routerComponent";

class RouterCont extends React.Component {
   render() {
      return <RouterComp {...this.props} />;
   }
}
const mapStateToProps = (state) => {
   return {
      auth: state.auth,
      darkly: state.darkly,
   };
};

export default connect(mapStateToProps, null)(RouterCont);
