import React from "react";

import InputMask from "react-input-mask";

export default function InputBox({ placeholder, onChange, value, type, onKeyDown, className, autoFocus = false }) {
   return (
      <InputMask mask={type === "number" ? "+1 (999) 999-9999" : ""} value={value} onChange={onChange}>
         {(inputProps) => (
            <input
               type={type === "date" ? "date" : "text"}
               className={`p-3 common-input-style font-small ${className}`}
               autoFocus={autoFocus}
               value={value}
               onChange={onChange}
               placeholder={placeholder}
               onKeyDown={onKeyDown}
               {...inputProps}
            />
         )}
      </InputMask>
   );
}
