import React, { memo } from "react";
import ProfileDetails from "shared/components/profile/profileDetails";
import { getParam, formatPhoneNumberBackend } from "shared/actions/helpers";

import listIcon from "images/careTeam/more.svg";
import { checkParams } from "actions/sharedPatientDetails/sharedPatientDetailsActions";
const tableHeaderStyle = {
   border: "1px solid #ced4da",
   borderRight: "none",
   borderLeft: "none",
   background: "rgba(224, 224, 224, 0.2)",
   height: 44,
   display: "flex",
   alignItems: "center",
   justifyContent: "start",
   paddingLeft: 10,
   fontSize: "16px",
};
const tableHeaderConst = {
   border: "1px solid #ced4da",
   borderRight: "none",
   borderLeft: "none",
   background: "rgba(224, 224, 224, 0.2)",
   height: 44,
   display: "flex",
   alignItems: "center",
};

const tableHeaderStyleEx = {
   border: "1px solid #ced4da",
   borderRight: "none",
   borderLeft: "none",
   background: "rgba(224, 224, 224, 0.2)",
   height: 44,
   display: "flex",
   alignItems: "center",
   justifyContent: "start",
};

const TableViewActivity = memo(({ posts = [], isLoading = false, openModal = () => {} }) => {
   if (isLoading)
      return (
         <div className="post-card p-4">
            {Array(10)
               .fill()
               .map((o, index) => (
                  <div
                     key={index}
                     className="flex animate-pulse flex-row items-center h-full justify-center space-x-5 rounded-lg "
                  >
                     <div className="flex flex-col space-y-3 w-full px-2 py-2 rounded-lg">
                        <div className="w-full bg-gray-300 h-10 rounded-md mb-2"></div>
                     </div>
                  </div>
               ))}
         </div>
      );
   else if (posts.length === 0)
      return (
         <div className="flex items-center justify-center w-full h-[57vh] text-bold text-large text-grey5 no-content-available">
            No content available.
         </div>
      );
   else
      return (
         <div>
            <table className="w-full hidden sm:table" cellPadding="0" cellSpacing="0" border="0">
               <thead className="text-small">
                  <tr>
                     <th width="33%">
                        <div
                           className="table-head"
                           style={{
                              ...tableHeaderStyle,
                              borderTopLeftRadius: "8px",
                              borderLeft: "1px solid #ced4da",
                              paddingLeft: 15,
                           }}
                        >
                           Name
                        </div>
                     </th>
                     <th width="20%">
                        <div className="table-head " style={{ ...tableHeaderConst }}>
                           Relation
                        </div>
                     </th>
                     <th width="30%">
                        <div className="table-head " style={{ ...tableHeaderConst }}>
                           Mobile number
                        </div>
                     </th>
                     <th>
                        <div
                           className="table-head text-center"
                           style={{
                              ...tableHeaderStyleEx,
                              borderRight: "1px solid #ced4da",
                              borderTopRightRadius: "8px",
                              //   justifyContent: "center",
                              paddingLeft: "5px",
                           }}
                        >
                           Email
                        </div>
                     </th>
                  </tr>
               </thead>
               <tbody>
                  {posts.map((o, i) => {
                     const { careMember } = o;
                     const initialsApi = careMember?.name?.initials || false;
                     const color = careMember?.colorCode || window.initialColors[0];
                     const name = careMember?.name?.fullName;
                     const mobileNumber = formatPhoneNumberBackend(careMember?.contactInformation?.mobileNumber);
                     const relation = o?.relationship;
                     const email = getParam(careMember?.contactInformation?.email);
                     return (
                        <tr
                           key={o?.id || i}
                           onClick={() => {
                              openModal(o);
                           }}
                           className="cursor-pointer hover:bg-light-blue"
                        >
                           <td className="pointer py-2 ">
                              <ProfileDetails
                                 className="ml-4"
                                 title={name}
                                 post={o.type}
                                 color={color}
                                 initialsApi={initialsApi}
                                 name={name}
                              />
                           </td>
                           <td className="table-data">{getParam(relation)}</td>
                           <td className="table-data">{mobileNumber}</td>
                           <td>
                              <div className="table-data">{email}</div>
                           </td>
                        </tr>
                     );
                  })}
               </tbody>
            </table>
            <div className="sm:hidden w-full">
               {posts.map((o, i) => {
                  const { careMember } = o;
                  const initialsApi = careMember?.name?.initials || false;
                  const color = careMember?.colorCode || window.initialColors[0];
                  const name = careMember?.name?.fullName;
                  const isEmail = careMember?.contactInformation?.email || false;
                  const relation = o?.relationship;
                  const className = checkParams(isEmail, relation) ? "py-1" : "py-3";
                  return (
                     <div
                        onClick={() => {
                           openModal(o);
                        }}
                        className={`flex items-center justify-between ${className} hover:bg-light-blue`}
                        key={o?.id || i}
                     >
                        <ProfileDetails
                           title={name}
                           post={o.type}
                           color={color}
                           initialsApi={initialsApi}
                           name={name}
                           details={[{ title: isEmail }, { title: relation }]}
                        />
                        <img src={listIcon} alt="list" />
                     </div>
                  );
               })}
            </div>
         </div>
      );
});

export default TableViewActivity;
