import { getPatientProfileData, getCareteamProviders, getCareteam } from "actions";

export const getPatientProfile = (params, callback) => {
   getPatientProfileData(params)
      .then((res) => {
         callback(res.data.data);
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getCareTeam = (params, callback) => {
   getCareteamProviders(params)
      .then((res) => {
         callback(res.data);
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getShareTeam = (params, callback) => {
   getCareteam(params)
      .then((res) => {
         callback(res.data);
      })
      .catch((err) => {
         console.log(err);
      });
};

export const checkParams = (primaryPayload, secondaryPayload) => {
   if (
      primaryPayload !== undefined &&
      primaryPayload !== null &&
      primaryPayload !== "" &&
      secondaryPayload !== undefined &&
      secondaryPayload !== null &&
      secondaryPayload !== ""
   )
      return true;
   else return false;
};
