import React from "react";
import cross from "images/common/cross.svg";
import LoadingIndicator from "shared/components/loader";
import Button from "shared/components/button";
import ButtonCancel from "shared/components/cancelButton";
import { textLabels } from "shared/constants/const";
import InputBox from "shared/components/inputBox";
import { placeholders } from "shared/constants/settingsConst";
const style = " w-full py-2";

export default function EditDialog({
   title,
   onModalTapped,
   loading = false,
   validate = () => {},
   selected,
   fname,
   lname,
   mname,
   setFname,
   setMname,
   setLname,
   email,
   mobileNumber,
   setEmail,
   setMobileNumber,
}) {
   const hideButton = selected === 2 || selected === 6;
   const forEmail = selected === 4;
   const forNum = selected === 5;
   return (
      <div className="w-[90%] sm:w-[470px] post-card flex items-center justify-center flex-col pt-5 relative pb-5">
         <img onClick={onModalTapped} src={cross} alt="cross-btn" className="absolute right-5 top-5 cursor-pointer" />
         <div className="pl-5 w-full font-semibold text-xl pb-3">{title}</div>
         {selected === 1 && (
            <div className="w-[91%] ">
               <div className={style}>
                  <InputBox placeholder={placeholders.fname} type="text" value={fname} onChange={setFname} />
               </div>
               <div className={style}>
                  <InputBox type="text" placeholder={placeholders.mname} value={mname} onChange={setMname} />
               </div>
               <div className={style}>
                  <InputBox type="text" placeholder={placeholders.lname} value={lname} onChange={setLname} />
               </div>
            </div>
         )}

         {forEmail && (
            <div className="w-[91%] ">
               <div className={style}>
                  <InputBox type="text" placeholder={placeholders.email} value={email} onChange={setEmail} />
               </div>
            </div>
         )}
         {forNum && (
            <div className="w-[91%] ">
               <div className={style}>
                  <InputBox
                     type="number"
                     placeholder={placeholders.num}
                     value={mobileNumber}
                     onChange={setMobileNumber}
                  />
               </div>
            </div>
         )}

         {!hideButton && (
            <div className="sm:flex mt-8 sm:w-full sm:w-max w-[90%] m-auto">
               {loading ? (
                  <LoadingIndicator />
               ) : (
                  <>
                     <ButtonCancel
                        className=" hidden sm:block sm:table h-[44px]  sm:w-[120px] sm:h-[50px]"
                        onClick={onModalTapped}
                     >
                        {textLabels.cancel}
                     </ButtonCancel>
                     <Button className=" w-[100%] mt-2 sm:mt-0 sm:w-[120px] sm:ml-3 h-[50px]" onClick={validate}>
                        {textLabels.save}
                     </Button>
                  </>
               )}
            </div>
         )}
      </div>
   );
}
