import React, { useState } from "react";
import rightArrow from "images/dashboard/rightArrow.svg";
import ModalPopup from "shared/components/modal/modal";
import ActivatePatient from "modules/banner/Component/activatePatinent";

export default function Banner({ banner }) {
   const [show, setShow] = useState(false);
   const title = banner[0].title;
   const subtitle = banner[0].subtitle;
   const showHide = () => {
      setShow(!show);
   };
   return (
      <div className="h-20 banner-container sm:h-[120px] relative cursor-pointer">
         <div onClick={showHide} className="h-[20px] banner-image sm:h-[130px]">
            <div className="pt-5 sm:pt-0 inline flex items-center justify-between ">
               <div className="pl-3 sm:pl-[100px] sm:pt-0">
                  <div className=" font-17 sm:text-[28px] sm:pt-[35px] sm:pb-[10px] header-banner">{title}</div>
                  <div className="text-[11px] subtitle-banner sm:font-17">{subtitle}</div>
               </div>
               <img src={rightArrow} alt="right-arrow" className="inline mr-5 sm:hidden" />
               <button className="hidden sm:inline button-red-banner">Click Here</button>
            </div>
         </div>
         {show && (
            <ModalPopup onModalTapped={showHide}>
               <ActivatePatient onModalTapped={showHide} />
            </ModalPopup>
         )}
      </div>
   );
}
