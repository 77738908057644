import React, { useCallback, memo } from "react";
import { calculateDateLabel, getMediaIconNew } from "shared/actions/helpers";
import refer from "images/media-icons/media-icon-referral.svg";
const tableHeaderStyle = {
   border: "1px solid #ced4da",
   borderRight: "none",
   borderLeft: "none",
   background: "rgba(224, 224, 224, 0.2)",
   height: 44,
   display: "flex",
   alignItems: "center",
   justifyContent: "start",
   paddingLeft: 10,
   fontSize: "16px",
};
const tableHeaderConst = {
   border: "1px solid #ced4da",
   borderRight: "none",
   borderLeft: "none",
   background: "rgba(224, 224, 224, 0.2)",
   height: 44,
   display: "flex",
   alignItems: "center",
   // justifyContent: "center",
};

const tableHeaderStyleEx = {
   border: "1px solid #ced4da",
   borderRight: "none",
   borderLeft: "none",
   background: "rgba(224, 224, 224, 0.2)",
   height: 44,
   display: "flex",
   alignItems: "center",
   justifyContent: "start",
};

const TableView = memo(({ posts = [], isLoading = false, openModal = () => {} }) => {
   const getIconSrc = useCallback(
      (indx) => {
         if (posts[indx]?.type === "referral") {
            return refer;
         } else {
            return getMediaIconNew(posts[indx]?.type);
         }
      },
      [posts]
   );

   if (isLoading)
      return (
         <div className="post-card p-4">
            {Array(10)
               .fill()
               .map((o, index) => (
                  <div
                     key={index}
                     className="flex animate-pulse flex-row items-center h-full justify-center space-x-5 rounded-lg "
                  >
                     <div className="flex flex-col space-y-3 w-full px-2 py-2 rounded-lg">
                        <div className="w-full bg-gray-300 h-10 rounded-md mb-2"></div>
                     </div>
                  </div>
               ))}
         </div>
      );
   else if (posts.length === 0)
      return (
         <div className="flex items-center justify-center w-full h-[57vh] text-bold text-large text-grey5 no-content-available">
            No content available.
         </div>
      );
   else
      return (
         <table className="w-full" cellPadding="0" cellSpacing="0" border="0">
            <thead className="text-small">
               <tr>
                  <th width="40%">
                     <div
                        className="table-head"
                        style={{
                           ...tableHeaderStyle,
                           borderTopLeftRadius: "8px",
                           borderLeft: "1px solid #ced4da",
                           paddingLeft: 15,
                        }}
                     >
                        Name
                     </div>
                  </th>
                  <th width="40%" align="left">
                     <div className="table-head " style={{ ...tableHeaderConst }}>
                        Updated by
                     </div>
                  </th>

                  <th>
                     <div
                        className="table-head text-center"
                        style={{
                           ...tableHeaderStyleEx,
                           borderRight: "1px solid #ced4da",
                           borderTopRightRadius: "8px",
                           justifyContent: "center",
                           paddingLeft: "5px",
                        }}
                     >
                        Added On
                     </div>
                  </th>
               </tr>
            </thead>
            <tbody>
               {posts.map((o, i) => {
                  return (
                     <tr
                        key={o?.id || i}
                        onClick={() => {
                           openModal(o);
                        }}
                        className="cursor-pointer hover:bg-light-blue"
                     >
                        <td className="p-0 pointer">
                           <div
                              className="flex items-center h-100 justify-content-start ml-4 table-data"
                              style={{ padding: "8px 0" }}
                           >
                              <img
                                 className="mr-3"
                                 src={getIconSrc(i)}
                                 alt={"ftype"}
                                 style={{
                                    maxWidth: "30px",
                                    maxHeight: "30px",
                                    minHeight: "30px",
                                    minWidth: "30px",
                                 }}
                              />

                              <div title={o?.title} className="table-data text-center truncate...">
                                 {o?.title.substring(0, 30)}
                                 {o.title.length > 30 && "..."}
                              </div>
                           </div>
                        </td>
                        <td className="table-data text-left">{o?.provider?.name?.fullName || "-"}</td>

                        <td className="table-data text-center">
                           <div>
                              <div title={calculateDateLabel(o?.createdAt)} className="table-data">
                                 {calculateDateLabel(o?.createdAt)}
                              </div>
                           </div>
                        </td>
                     </tr>
                  );
               })}
            </tbody>
         </table>
      );
});

export default TableView;
