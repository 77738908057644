import React from "react";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import "react-datepicker/dist/react-datepicker.css";
import { placeholders } from "shared/constants/loginConst";
export default function CustomDatePicker({ onSelect, onChange, value, id = "" }) {
   return (
      <DatePicker
         id={id}
         onSelect={onSelect}
         onChange={onChange}
         value={value}
         selected={value}
         showMonthDropdown={true}
         showYearDropdown={true}
         dropdownMode="select"
         className={` mt-2 p-[13px] font-small common-input-style`}
         autoComplete="off"
         placeholderText={placeholders.dateOfBirth}
         dateFormat="MM/dd/yyyy"
         maxDate={new Date()}
         customInput={<InputMask mask="99/99/9999">{(inputProps) => <input {...inputProps} />}</InputMask>}
      />
   );
}
