import React, { useState } from "react";
import ChangeNotifications from "modules/changeNotifications/component/changeNotificationsComp";
import { connect } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import { GET_NOTIFICATION_SETTINGS, UPDATE_NOTIFICATION_SETTINGS } from "../actions/changeNotificationsActions";
import { ShowAlert } from "shared/actions/alert";

const ChangeNotificationsCont = (props) => {
   const [sms, setSms] = useState(false);
   const [email, setEmail] = useState(false);
   const [pNotif, setPNotif] = useState(false);
   const { refetch } = useQuery(GET_NOTIFICATION_SETTINGS, {
      fetchPolicy: "no-cache",
      onCompleted(result) {
         const data = result.getProfile.notificationSettings;
         setSms(data.sms);
         setEmail(data.email);
         setPNotif(data.push);
      },
   });

   const [updateNotification] = useMutation(UPDATE_NOTIFICATION_SETTINGS, {
      onCompleted(res) {
         const data = res.updateProfile.user.notificationSettings;
         setSms(data.sms);
         setEmail(data.email);
         setPNotif(data.push);
         ShowAlert(res.updateProfile?.status?.message);
      },
      onError() {
         ShowAlert("Something went wrong!", "error");
         refetch();
      },
   });

   const updatePatientNotifications = (smsPay, emailPay, pushpay) => {
      const payload = {
         user: {
            notificationSettings: [
               {
                  sms: smsPay,
                  email: emailPay,
                  push: pushpay,
               },
            ],
         },
      };
      updateNotification({
         variables: payload,
      });
   };

   return (
      <ChangeNotifications
         {...props}
         sms={sms}
         setSms={setSms}
         email={email}
         setEmail={setEmail}
         pNotif={pNotif}
         setPNotif={setPNotif}
         updatePatientNotifications={updatePatientNotifications}
      />
   );
};

export default connect(null, null)(ChangeNotificationsCont);
