import React from "react";
import { getFormattedDate } from "shared/actions/helpers";
import back from "images/careTeam/Back.svg";

export default function HeaderSmall({ patientData, navigate }) {
   const name = patientData?.name?.fullName || "";
   const isDob = patientData?.dob || false;
   const showCard = patientData !== null;
   return (
      <div
         id="small-header"
         className={`  mt-6  bg-[ rgba(224, 224, 224, 0.2);] ${
            !showCard && " animate-pulse h-[80px] bg-gray-200 rounded-md "
         } `}
      >
         {showCard && (
            <>
               <div className="text-[#17182D] flex">
                  <img onClick={navigate} src={back} alt="back-icon" />
                  <div className=" font-bold text-[22px]">
                     <div>{name}</div>
                     <div className="font-normal text-base">{getFormattedDate(isDob)}</div>
                  </div>
               </div>
            </>
         )}
      </div>
   );
}
