import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import ToastView, { defaultToastProps } from "shared/components/toastView";

const swalWithBootstrapButtons = Swal.mixin({
   customClass: {
      confirmButton: " primary-bg text-white p-2 rounded-lg ml-3 px-4 outline-0",
      cancelButton: "text-black bg-cancel p-2 rounded-lg px-4",
   },
   buttonsStyling: false,
});

const showSwal = async (
   titleText = "new",
   contentText = "val",
   callback = () => {},
   confirmButtonText = "Confirm",
   cancelButtonText = "Cancel"
) => {
   return swalWithBootstrapButtons
      .fire({
         title: titleText,
         text: contentText,
         reverseButtons: true,
         showCancelButton: true,
         confirmButtonText: confirmButtonText,
         cancelButtonText: cancelButtonText,
      })
      .then((res) => {
         if (res.isConfirmed) {
            callback();
         }
      });
};

const ShowAlert = (text, type) => toast(<ToastView text={text} type={type} />, defaultToastProps);

export { ShowAlert, showSwal };
