import React from "react";

import { options } from "shared/constants/sharedPatient";
export default function SharedTabs({ selected, onClick }) {
   return (
      <div className="pt-8 flex sm:ml-2 sm:justify-start justify-evenly ml-[-15px] sm:border-shared-patient">
         {options.map((s, i) => (
            <div
               onClick={() => {
                  onClick(i);
               }}
               className={` sm:w-[100px] sm:text-center cursor-pointer ${i !== 0 && "ml-10 "} text-sm sm:text-lg ${
                  selected === i ? "color-playback-base-responsive pb-2 font-bold" : "text-[#1D1D1B] font-medium"
               } `}
               key={s.text}
            >
               {s.text}
            </div>
         ))}
      </div>
   );
}
