import React, { memo } from "react";
import Avatar from "../avatar";
import care from "images/careTeam/care.svg";

const ProfileDetails = memo(
   ({ title, post, color, initialsApi, name, className = false, details = [], showCare = false, src = "" }) => {
      return (
         <div className="flex sm:items-center">
            <div className={`flex sm:items-center h-100 justify-content-start ${className} table-data`}>
               <Avatar
                  src={src}
                  isProvider={true}
                  className="sm:flex-shrink-0 pointer mr-4 mt-[5px] sm:mt-0"
                  bgColor={color}
                  radius={38}
                  initialsApi={initialsApi}
                  name={name}
               />
            </div>
            <div className="pl-2">
               <div title={title} className="truncate... text-[17px] mb-[-3px]">
                  {title}
               </div>
               {post && (
                  <div title={post} className="text-xs text-grey-cus">
                     {post}
                  </div>
               )}
               {details.map((obj, index) => {
                  const hasCareGroup = index === 1 && showCare;
                  const className = hasCareGroup ? "flex items-end -mt-1" : "py-[2px]";
                  return (
                     <div key={index} className={`text-xs text-grey-cus ${className}`}>
                        {obj.title} {hasCareGroup && <img src={care} alt="careGroup" className="inline ml-2" />}
                     </div>
                  );
               })}
            </div>
         </div>
      );
   }
);

export default ProfileDetails;
