import React, { useState } from "react";
import arrowBack from "images/dashboard/arrow-back.svg";

export default function Search({ onModalTapped, setSearch, search }) {
   const [searchKey, setSearchKey] = useState(search);
   return (
      <div className="w-[100%] text-center flex items-center filter-popup px-2 bg-white py-2 ">
         <img onClick={onModalTapped} src={arrowBack} alt="back-arrow" className="ml-2" />
         <input
            type="text"
            placeholder="Search"
            className="search-input-profile-filter outline-0 w-full ml-2"
            value={searchKey}
            onKeyDown={(event) => {
               if (event.key === "Enter") {
                  setSearch(searchKey);
                  onModalTapped();
               }
            }}
            onChange={(e) => {
               setSearchKey(e.target.value);
            }}
         />
      </div>
   );
}
