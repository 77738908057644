import React from "react";
export default function Switch({ value = false, onChange }) {
   return (
      <div>
         <label class="switch">
            <input
               type="checkbox"
               checked={value}
               onChange={() => {
                  onChange(!value);
               }}
            />
            <span class="slider round"></span>
         </label>
      </div>
   );
}
