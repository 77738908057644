import { client } from "wrappers/apolloWrapper";

export const fetchMutation = async (mutation, variables = {}, callback = () => {}, error = () => {}) => {
   client
      .mutate({
         mutation: mutation,
         variables: variables,
      })
      .then((data) => {
         callback(data);
      })
      .catch((err) => {
         error(err);
      });
};
