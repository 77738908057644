import React, { useCallback, memo } from "react";
import { calculateDateLabel, getMediaIconNew } from "shared/actions/helpers";
import refer from "images/media-icons/media-icon-referral.svg";

const XSTable = memo(({ posts = [], isLoading = false, openModal = () => {} }) => {
   const getIconSrc = useCallback(
      (indx) => {
         if (posts[indx]?.type === "referral") {
            return refer;
         } else {
            return getMediaIconNew(posts[indx]?.type);
         }
      },
      [posts]
   );

   return (
      <div>
         {posts.map((o, i) => (
            <div
               key={o?.id || i}
               onClick={() => {
                  openModal(o);
               }}
               className="cursor-pointer hover:bg-light-blue flex item-center pt-2 pb-2"
            >
               <div className="flex items-center h-100 justify-content-start ml-4 table-data">
                  <img
                     className="mr-3"
                     src={getIconSrc(i)}
                     alt={"ftype"}
                     style={{
                        maxWidth: "30px",
                        maxHeight: "30px",
                        minHeight: "30px",
                        minWidth: "30px",
                     }}
                  />
               </div>
               <div>
                  <div title={o?.title} className="truncate... text-sm">
                     {o?.title.substring(0, 25)} {o.title.length > 25 && " ..."}
                  </div>
                  <div title={calculateDateLabel(o?.createdAt)} className="text-xs">
                     {calculateDateLabel(o?.createdAt)}
                  </div>
               </div>
            </div>
         ))}
      </div>
   );
});

export default XSTable;
