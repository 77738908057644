import React from "react";
export default function SearchField({ searchKey, setSearchKey }) {
   return (
      <input
         type="text"
         placeholder="Search"
         className="mt-4 w-full sm:w-[343px]  sm:mt-0 search-input-profile-shared outline-0"
         value={searchKey}
         onChange={(e) => {
            setSearchKey(e.target.value);
         }}
      />
   );
}
