import React from "react";
import Avatar from "shared/components/avatar";
import { settingsConst } from "shared/constants/settingsConst";
import Row from "./eachRow";
import lock from "images/settings/lock.svg";
import bar from "images/settings/bar.svg";

const SettingsRow = ({
   name,
   user,
   color,
   initialsApi,
   toggle,
   patientName,
   dob,
   patientEmail,
   patientMobileNumber,
   openDialog = () => {},
   setDeleteButton,
   disabled = false,
   showError,
}) => {
   const phoneNumber = patientMobileNumber ? "+1 " + patientMobileNumber : "";
   return (
      <div>
         <Row
            title={settingsConst.name}
            action={settingsConst.edit}
            val={patientName}
            open={openDialog}
            selection={1}
         />
         <Row
            selection={2}
            disabled={disabled}
            open={openDialog}
            title={settingsConst.photo}
            action={settingsConst.edit}
            view={() => {
               return (
                  <Avatar
                     showError={showError}
                     src={`${process.env.REACT_APP_PROFILE_URL}/${user?.id}?ver=${Math.random()}`}
                     className="shrink-0 pointer mr-4"
                     bgColor={color}
                     radius={38}
                     gotImage={setDeleteButton}
                     initialsApi={initialsApi}
                     name={name}
                     fromEditImg={true}
                  />
               );
            }}
         />
         <Row
            selection={3}
            title={settingsConst.dob}
            val={dob || true}
            view={() => {
               return <img src={lock} alt="lock" />;
            }}
         />
         <Row
            selection={4}
            open={openDialog}
            title={settingsConst.email}
            action={settingsConst.edit}
            val={patientEmail}
         />
         <Row selection={5} open={openDialog} title={settingsConst.num} action={settingsConst.edit} val={phoneNumber} />
         <Row
            selection={6}
            open={toggle}
            title={settingsConst.code}
            action={settingsConst.show}
            view={() => {
               return <img src={bar} alt="bar" />;
            }}
         />
      </div>
   );
};

export default SettingsRow;
