import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ children }) => {
   const { pathname } = useLocation();

   useEffect(() => {
      document.getElementById("div-scroll-top") && document.getElementById("div-scroll-top").scroll({ top: 0 });
   }, [pathname]);

   return children || null;
};

export default ScrollToTop;
