import React, { useState, useEffect } from "react";
import TableViewActivity from "./tableShareTeam";
import SearchField from "./searchField";
import { getFilteredResults, getSharedPatientList } from "modules/sharedPatientDetails/action/sharedPatientAction";

export default function ShareTeam({ openModal, id }) {
   const [shareTeam, setShareTeam] = useState([]);
   const [searchKey, setSearchKey] = useState("");
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      getSharedPatientList(id, (data) => {
         setShareTeam(data);
         setLoading(false);
      });
   }, []);

   return (
      <div>
         <div className="text-2xl text-black font-medium pb-2 sm:pb-6">
            <SearchField searchKey={searchKey} setSearchKey={setSearchKey} />
         </div>
         <TableViewActivity
            posts={getFilteredResults(searchKey, shareTeam)}
            isLoading={loading}
            openModal={openModal}
         />
      </div>
   );
}
