import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { configure } from "shared/constants/const";
import PatientContainer from "modules/patientOnboard/container/patientContainer";
import LoginCont from "modules/login/container/loginCont";
import Socket from "socket/Websocket";
import Home from "modules/home/container/homeCont";
import NavbarCont from "modules/navbar/container/navBarContainer";
import CareTeamCont from "modules/careTeam/container/careTeamCont";
import ShareCont from "modules/shareFamilyFriends/container/shareCont";
import SettingsCont from "modules/settings/container/settingsContainer";
import SharedPatientDetailsCont from "modules/sharedPatientDetails/container/sharedPatientDetailsCont";
import ChangeNotificationsCont from "modules/changeNotifications/container/changeNotificationsContainer";
import WithApolloClient from "wrappers/apolloWrapper";
import ScrollToTop from "./scrollToTop";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

window.socket = new Socket();
firebase.initializeApp(configure);

function RouterComp(props) {
   const { auth, darkly } = props;
   const showNotificationSettings = darkly.ldFeatureFlags?.graphqlNotification || false;
   let body = "";
   const isLoggedIn = auth.userCredentials !== null && auth.userCredentials !== undefined;

   if (isLoggedIn) {
      body = (
         <div className="px-3 sm:px-2">
            <NavbarCont />
            <Routes>
               <Route path="/" element={<Home />} />
               <Route exact path="/care-team" element={<CareTeamCont />} />
               <Route exact path="/sharing" element={<ShareCont />} />
               <Route exact path="/sharing/:id" element={<SharedPatientDetailsCont />} />
               <Route exact path="/settings" element={<SettingsCont />} />
               {showNotificationSettings && (
                  <Route exact path="/notifications_settings" element={<ChangeNotificationsCont />} />
               )}
               <Route path="*" element={<Home />} />
            </Routes>
         </div>
      );
   } else {
      body = (
         <Routes>
            <Route exact path="/sign-up" element={<PatientContainer />} />
            <Route path="/" element={<LoginCont />} />
            <Route path="*" element={<LoginCont />} />
         </Routes>
      );
   }

   return (
      <div className={`bg-white sm:m-auto ${isLoggedIn && "max-w-[1240px]"} `}>
         <ToastContainer />
         <BrowserRouter>
            <ScrollToTop />
            {body}
         </BrowserRouter>
      </div>
   );
}

export default WithApolloClient(RouterComp);
