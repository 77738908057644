import React, { memo } from "react";
import ProfileDetails from "shared/components/profile/profileDetails";
import listIcon from "images/careTeam/more.svg";
import { emptyStatesText, textLabels } from "shared/constants/const";
import EmptyStateComp from "shared/components/emptyStates/emptyState";
import ShareImg from "images/emptyStates/share.svg";

const TableEach = ({ members, openModal, share = false, navigate }) => {
   return (
      <div className="sm:hidden w-full">
         {members
            .filter((filter) => {
               return share ? !filter.isCareTeam : filter.isCareTeam;
            })
            .map((o) => {
               const { careMember } = o;
               const contactInfo = careMember?.contactInformation;
               const initialsApi = careMember?.name?.initials || false;
               const color = careMember?.colorCode || window.initialColors[0];
               const fullName = careMember?.name?.fullName;
               const isEmail = contactInfo?.email || false;
               const relation = o?.relationship;
               return (
                  <div
                     key={o.id || o.name}
                     onClick={() => {
                        if (share) {
                           navigate(`/sharing/${careMember?.id}`);
                        } else {
                           openModal(o);
                        }
                     }}
                     className="flex items-center justify-between py-[7px] sm:py-3  hover:bg-light-blue"
                  >
                     <ProfileDetails
                        src={`${process.env.REACT_APP_PROFILE_URL}/${careMember?.id}`}
                        className="ml-3"
                        title={fullName}
                        post={o.type}
                        color={color}
                        initialsApi={initialsApi}
                        name={fullName}
                        details={[{ title: isEmail }, { title: relation }]}
                     />
                     <img
                        className="mr-1"
                        src={listIcon}
                        alt="list"
                        onClick={(e) => {
                           if (share) {
                              e.stopPropagation();
                              openModal(o, false);
                           }
                        }}
                     />
                  </div>
               );
            })}
      </div>
   );
};

const TableCareTeamSmall = memo(
   ({ members = [], isLoading = false, openModal = () => {}, navigate, inviteClick = () => {} }) => {
      if (isLoading)
         return (
            <div className="post-card p-4">
               {Array(10)
                  .fill()
                  .map((o, index) => (
                     <div
                        key={index}
                        className="flex animate-pulse flex-row items-center h-full justify-center space-x-1 rounded-lg "
                     >
                        <div className="flex flex-col space-y-3 w-full px-2 py-1 rounded-lg">
                           <div className="w-full bg-gray-300 h-10 rounded-md mb-2"></div>
                        </div>
                     </div>
                  ))}
            </div>
         );
      else if (members.length === 0)
         return (
            <div className="flex items-center justify-center w-full h-[57vh] text-bold text-large text-grey5 no-content-available">
               <EmptyStateComp
                  src={ShareImg}
                  headerText={emptyStatesText.emptyShare}
                  btnText={emptyStatesText.emptyShareBtnTxt}
                  description={emptyStatesText.emptyShareText}
                  onClick={inviteClick}
               />
            </div>
         );
      else
         return (
            <div>
               <TableEach members={members} openModal={openModal} />
               <div className=" text-base sm:text-2xl  font-bold pl-3 flex sm:hidden py-2">
                  {textLabels.sharedOptions[1]}
               </div>
               <TableEach members={members} openModal={openModal} share={true} navigate={navigate} />
            </div>
         );
   }
);

export default TableCareTeamSmall;
