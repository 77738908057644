import React from "react";
import { ReactComponent as LoveSvg } from "images/love-icon.svg";
import { ReactComponent as FilledLoveSvg } from "images/dashboard/redHeart.svg";
import PostView from "./PostView";
import { calculateDateLabel, getMediaIconNew } from "shared/actions/helpers";
import { getAccessToken, giveReaction } from "actions/index";
import { connect } from "react-redux";
import hospital from "images/iconsNotif/hospital.svg";
import cross from "images/common/cross.svg";

function Post(props) {
   const {
      data,
      isLoading,
      auth,
      openModal = () => {},
      isPreview = false,
      reactHeart,
      onModalTapped = () => {},
   } = props;
   const getDate = calculateDateLabel(data?.createdAt);
   const token = getAccessToken();
   const isLoved =
      data?.loves?.some((find) => {
         return find === auth.userCredentials.user.id;
      }) || false;

   const postReact = (e) => {
      e.stopPropagation();
      var obj = null;
      if (data.type?.includes("item") || data.type?.includes("text")) {
         obj = {
            itemId: data.id,
            type: "loves",
         };
      } else {
         obj = {
            mediaId: data.id,
            type: "loves",
         };
      }

      giveReaction(obj).then(() => {
         reactHeart(data, auth.userCredentials.user.id);
      });
   };
   const showReact = data?.type !== "referral";
   return (
      <div
         key={data.id}
         onClick={() => {
            openModal(data);
         }}
         className={`${
            isPreview ? "sm:w-8/12 w-[100%] z-[50]  " : "w-[98%] sm:w-2/4 md:w-[33%]"
         } p-3 pr-0 pl-0 sm:pr-2 sm:pl-2 sm:pl-4 cursor-pointer `}
      >
         <div
            className={`post-card-post flex flex-col relative ${
               isPreview ? "rounded-[0px] sm:rounded-[15px] " : " rounded-[15px] "
            }  `}
         >
            {isPreview && (
               <img
                  onClick={onModalTapped}
                  src={cross}
                  alt="cross-btn"
                  className="absolute w-[25px] h-[25px]  bg-white z-[70] rounded-lg p-[5px] right-5 top-5 cursor-pointer"
               />
            )}
            <div
               className={`absolute left-3 top-3 rounded-[5px] time-bg pl-1 pr-1 text-[13px] text-white font-medium ${
                  isPreview ? "z-20" : "z-[30]"
               }`}
            >
               {getDate}
            </div>
            <div
               className={`${isLoading && "loading-shade"} ${
                  isPreview ? " h-[100vh] sm:h-[500px] flex items-center justify-center " : " h-[220px]"
               } w-full `}
            >
               <PostView data={data} accessToken={token} isPreview={isPreview} />
            </div>
            <div className={` ${isPreview && "hidden sm:block"}  z-10 post-bottom border-t-[1px] pl-3`}>
               <div title={data?.title} className="text-[20px] font-medium pl-1 truncate ... pt-2">
                  {data.title}
               </div>
               <div className="flex items-center justify-between">
                  <div className="flex items-center pb-2 ">
                     <img
                        className="h-[25px] w-[25px] "
                        alt="hospitalIcon"
                        onError={(e) => {
                           e.target.src = hospital;
                        }}
                        src={getMediaIconNew(data?.type, true, data.hospital?.id)}
                     />
                     <div title={data.addedByName} className="font-17 font-medium truncate... ml-3 py-1">
                        {data.provider?.name?.fullName}
                     </div>
                  </div>
                  {showReact && (
                     <div className="mr-2 mt-[-8px]">
                        {isLoved ? <FilledLoveSvg onClick={postReact} /> : <LoveSvg onClick={postReact} />}
                     </div>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
}

const mapStateToProps = (state) => {
   return {
      auth: state.auth,
   };
};

export default connect(mapStateToProps, null)(Post);
