export default function initAutocomplete(latlong = false) {
   const nor = { lat: 40.7128, lng: 74.006 };
   const latobj = latlong ? { lat: parseFloat(latlong.lat), lng: parseFloat(latlong.long) } : nor;
   const map = new window.google.maps.Map(document.getElementById("map"), {
      center: latobj,
      zoom: 13,
      mapTypeId: "roadmap",
   });

   new window.google.maps.Marker({
      position: latobj,
      map: map,
   });

   // Create the search box and link it to the UI element.
   //    const input = document.getElementById("pac-input");
   //    const searchBox = new window.google.maps.places.SearchBox(input);

   //    //    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
   //    // Bias the SearchBox results towards current map's viewport.
   //    map.addListener("bounds_changed", () => {
   //       searchBox.setBounds(map.getBounds());
   //    });

   //    let markers = [];

   //    // Listen for the event fired when the user selects a prediction and retrieve
   //    // more details for that place.
   //    searchBox.addListener("places_changed", () => {
   //       const places = searchBox.getPlaces();
   //       callback(places);

   //       if (places.length === 0) {
   //          return;
   //       }

   //       // Clear out the old markers.
   //       markers.forEach((marker) => {
   //          marker.setMap(null);
   //       });
   //       markers = [];

   //       // For each place, get the icon, name and location.
   //       const bounds = new window.google.maps.LatLngBounds();

   //       places.forEach((place) => {
   //          if (!place.geometry || !place.geometry.location) {
   //             console.log("Returned place contains no geometry");
   //             return;
   //          }

   //          const icon = {
   //             url: place.icon,
   //             size: new window.google.maps.Size(71, 71),
   //             origin: new window.google.maps.Point(0, 0),
   //             anchor: new window.google.maps.Point(17, 34),
   //             scaledSize: new window.google.maps.Size(25, 25),
   //          };

   //          // Create a marker for each place.
   //          markers.push(
   //             new window.google.maps.Marker({
   //                map,
   //                icon,
   //                title: place.name,
   //                position: place.geometry.location,
   //             })
   //          );
   //          if (place.geometry.viewport) {
   //             // Only geocodes have viewport.
   //             bounds.union(place.geometry.viewport);
   //          } else {
   //             bounds.extend(place.geometry.location);
   //          }
   //       });
   //       map.fitBounds(bounds);
   //    });
   // }
}
