import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import DropOtionsCustom from "./dropDownOptionsCustom";
function Dropdowntoggle(props) {
   const [show, setShow] = useState(false);
   const {
      options = [],
      view = () => {
         return null;
      },
      onChange,
      anchor = false,
      careId = true,
      memberId = true,
      setMemberId = () => {},
   } = props;
   const setDrop = () => {
      setShow(!show);
      setMemberId(careId);
   };

   const checkCondition = memberId === careId;
   let className = "";
   if (checkCondition) {
      const el = window.document.getElementById(`dropdownOption-${careId}`);
      const rect = el && el.getBoundingClientRect().bottom;
      className = window.innerHeight - rect < 120 ? `bottom-[-10%] right-[-2%]` : "top-[30px]";
   }

   useEffect(() => {
      if (show && !checkCondition) setShow(false);
   }, [show, checkCondition]);
   return (
      <div className="relative">
         <div
            className="cursor-pointer select-none mr-2"
            onClick={(e) => {
               e.stopPropagation();
               setDrop();
            }}
         >
            {view()}
         </div>
         {show && checkCondition && (
            <div
               id="dropdownDivider"
               className={` drop-down ${show ? "" : "hidden"} absolute  ${
                  anchor ? className : "right-2 top-[40px]"
               } z-[90] w-max bg-white rounded shadow `}
            >
               <DropOtionsCustom options={options} setDrop={setDrop} onChange={onChange} />
            </div>
         )}
      </div>
   );
}
const mapStateToProps = (state) => {
   return {
      auth: state.auth,
   };
};

export default connect(mapStateToProps, null)(Dropdowntoggle);
