import React, { Fragment, useCallback, useState } from "react";
import getCroppedImg from "modules/settings/actions/cropImageAction";
import Cropper from "react-easy-crop";
import Button from "shared/components/button";
import ButtonCancel from "shared/components/cancelButton";
import { textConst, textLabels } from "shared/constants/const";

function ImageCropper(props) {
   const {
      profilePicPath = "",
      fileName = "",
      isPhotoSelected,
      croppedImage,
      initialState,
      setImagePersist,
      deleteProfilePhoto,
      updateProfilePhoto,
      setShowDP,
   } = props;
   const initialCropSize = {
      x: 0,
      y: 0,
      width: 75,
      height: 100,
   };
   const [crop, setCrop] = useState({ x: 0, y: 0 });
   const [zoom, setZoom] = useState(1);
   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

   const [cropSize, setCropSize] = useState(initialCropSize);

   const onCropComplete = (croppedArea, croppedAreaPixels, e) => {
      setCropSize(e);
      setCroppedAreaPixels(croppedAreaPixels);
   };

   const showCroppedImage = useCallback(async () => {
      try {
         const croppedImage = await getCroppedImg(
            profilePicPath,
            croppedAreaPixels,
            null,
            { horizontal: false, vertical: false },
            fileName
         );
         return croppedImage;
      } catch (e) {
         console.error(e);
      }
   }, [croppedAreaPixels]);
   return (
      <Fragment className={`${isPhotoSelected && "flex flex-col"}`}>
         {isPhotoSelected && (
            <>
               <div className="relative w-full h-[266px] bg-white">
                  <Cropper
                     image={croppedImage || profilePicPath}
                     crop={crop}
                     zoom={zoom}
                     aspect={1}
                     cropShape="round"
                     onCropChange={setCrop}
                     onCropComplete={onCropComplete}
                     onZoomChange={setZoom}
                  />
               </div>
               <div className="flex justify-center align-middle mt-5">
                  <h4 className="zoom-text">Zoom</h4>
                  <input
                     type="range"
                     min={1}
                     max={20}
                     value={zoom}
                     class="w-[60%] ml-2.5"
                     id="myRange"
                     aria-labelledby="Zoom"
                     onChange={(e) => setZoom(parseInt(e.target.value, 10))}
                  />
               </div>
            </>
         )}
         <div className={`flex flex-col-reverse sm:flex sm:flex-row w-full justify-center absolute sm:mt-5 mb-2`}>
            <ButtonCancel
               onClick={() => {
                  if (!isPhotoSelected) {
                     deleteProfilePhoto(false);
                  } else {
                     setImagePersist(false);
                     initialState();
                  }
               }}
               className="sm:block mt-2 sm:mt-0 sm:table h-[50px] sm:w-[120px] sm:h-[50px] max-w-[90%] mx-4"
            >
               {!isPhotoSelected ? textConst.removeHead : textLabels.changePhoto}
            </ButtonCancel>
            <Button
               onClick={async () => {
                  if (!isPhotoSelected) {
                     setImagePersist(false);
                     initialState();
                  } else {
                     if (cropSize !== initialCropSize) {
                        await showCroppedImage().then((res) => {
                           updateProfilePhoto(true, res);
                           setShowDP(true);
                        });
                     } else updateProfilePhoto();
                  }
               }}
               className="w-[100%] mt-2 sm:mt-0 sm:w-[120px] sm:ml-3 h-[50px] max-w-[90%] mx-4"
            >
               {!isPhotoSelected ? textLabels.changePhoto : textLabels.save}
            </Button>
         </div>
      </Fragment>
   );
}

export default ImageCropper;
