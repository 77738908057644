import React from "react";

const SkeletonCards = () => {
   return (
      <div className="w-full sm:w-2/4 md:w-[33%] p-2 ">
         <div className="flex animate-pulse flex-row items-center h-full justify-center space-x-5 rounded-lg ">
            <div className="flex flex-col space-y-3 w-full px-2 py-2 rounded-lg post-card ">
               <div className="w-full h-[200px] bg-gray-300  rounded-md py-2 "></div>
               <div className="w-full bg-gray-300 h-10 rounded-md mb-2"></div>
            </div>
         </div>
      </div>
   );
};
const SkeletonTable = () => {
   return (
      <div className="flex animate-pulse flex-row items-center h-full justify-center space-x-5 rounded-lg ">
         <div className="flex flex-col space-y-3 w-full px-2 py-2 rounded-lg">
            <div className="w-full bg-gray-300 h-10 rounded-md mb-2"></div>
         </div>
      </div>
   );
};
function SkeletonPost({ view }) {
   return (
      <div className={`${!view ? "flex items-center flex-wrap justify-between" : "mt-4"}`}>
         {view ? (
            <div className="post-card px-3 pb-3 ">
               {Array(10)
                  .fill()
                  .map((o, i) => (
                     <SkeletonTable key={i} />
                  ))}
            </div>
         ) : (
            Array(10)
               .fill()
               .map((o, i) => <SkeletonCards key={i} />)
         )}
      </div>
   );
}

export default SkeletonPost;
