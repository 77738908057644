import React, { useState } from "react";
import SettingsComponent from "modules/settings/component/settingsComp";
import { bindActionCreators } from "redux";
import * as actions from "redux/actions/auth.action";
import { connect } from "react-redux";
import { ShowAlert } from "shared/actions/alert";
import { errorToDisplay } from "shared/actions/helpers";
import { useMutation } from "@apollo/client";
import { UPDATE_PROFILE } from "actions/settings/settingsAction";
import { updateUser } from "actions/login/loginActions";

const SettingsCont = (props) => {
   const [loading, setLoading] = useState(false);
   const [show, setShow] = useState(false);

   const endCall = () => {
      setLoading(false);
      showHide();
   };

   const showHide = () => {
      setShow(!show);
   };

   const toggleLoading = () => {
      setLoading(!loading);
   };

   const [updateProfile] = useMutation(UPDATE_PROFILE, {
      onCompleted(res) {
         ShowAlert(res.updateProfile.status.message);
         endCall();
         updateUser(null, () => {}, false);
      },
      onError(err) {
         const errMessage = errorToDisplay(err);
         ShowAlert(errMessage, "error");
         setLoading(false);
      },
   });

   return (
      <SettingsComponent
         updatePatientProfile={updateProfile}
         showHide={showHide}
         toggleLoading={toggleLoading}
         show={show}
         setShow={setShow}
         loading={loading}
         setLoading={setLoading}
         {...props}
      />
   );
};

const mapStateToProps = (state) => {
   return {
      auth: state.auth,
   };
};
const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         savenorthwelluserobj: actions.savenorthwelluserobj,
         saveusercredentials: actions.saveusercredentials,
      },
      dispatch
   );
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsCont);
