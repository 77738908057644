import React, { useState } from "react";
import { textLabels } from "shared/constants/const";
import TableShare from "./tableShare";
import GetDirections from "modules/careTeam/component/directionsGql";
import InviteFamily from "./Invite";
import Modal from "shared/components/modal/modal";
import Button from "shared/components/button";
import { capitalizeFirstLetter, isValidUsNumber, unformatNumber } from "shared/actions/helpers";
import TableCareTeamSmall from "./smallScreenTable";
import { useNavigate } from "react-router-dom";
import { filterFamilyMembers } from "../actions/familyActions";

function Share(props) {
   const navigate = useNavigate();
   const {
      familyFriendsList,
      loading,
      removeFamilyMember,
      setSelectedPatient,
      addToFriendsFamily,
      setLoadingInvite,
      loadingInvite,
      invite,
      setInvite,
   } = props;
   const [tab, setTab] = useState(0);
   const [emailInvite, setEmailInvite] = useState("");
   const [nameInvite, setNameInvite] = useState("");
   const [relation, setRelation] = useState("");
   const [eachCare, setEachCare] = useState(null);
   const [showRemove, setShowRemove] = useState(false);
   const [showDirection, setShowDirection] = useState(false);
   const [selected, setSelected] = useState(null);

   const hide = () => {
      setShowDirection(false);
   };

   const inviteClick = () => {
      setSelected(null);
      setInvite(!invite);
      setEmailInvite("");
      setNameInvite("");
      setRelation("");
   };

   const invitePerson = () => {
      let obj = null;
      if (!isValidUsNumber(emailInvite)) {
         obj = {
            email: emailInvite,
            name: nameInvite,
            relationship: relation?.label || "",
         };
      } else {
         obj = {
            mobileNo:
               (emailInvite.includes("+1") || emailInvite.startsWith("1") ? "+" : "+1") + unformatNumber(emailInvite),
            name: nameInvite,
            relationship: relation.label,
         };
      }
      setLoadingInvite(true);
      addToFriendsFamily(obj);
   };

   const edit = (family) => {
      const email = family?.careMember?.contactInformation?.email;
      const phone = family?.careMember?.contactInformation?.mobileNumber;
      const name = family.careMember.name.fullName;
      const rel = family.relationship;

      const relation = rel !== null && rel !== "" ? { label: rel, text: rel } : "";
      setSelected(family);
      setInvite(true);
      setEmailInvite(email || phone);
      setNameInvite(name);
      setRelation(relation);
   };
   const showSharedByMe = filterFamilyMembers(0, familyFriendsList)?.length || 0;

   const filteredMembers = filterFamilyMembers(tab, familyFriendsList);
   const checkMembers = filteredMembers?.length === 0;

   return (
      <div className="mt-5 ">
         <div className="flex items-center justify-between mb-2 sm:mb-4">
            <div className="text-[22px]   pl-3  hidden sm:flex">
               {[
                  textLabels.sharedOptions.map((s, i) => (
                     <div
                        onClick={() => {
                           setTab(i);
                        }}
                        key={s}
                        className={` cursor-pointer  ${tab !== i ? "text-[#8C8C8C] font-normal " : "font-bold"} ${
                           i === 1 && "ml-6"
                        }  `}
                     >
                        {s}
                     </div>
                  )),
               ]}
            </div>

            <div className=" text-base sm:text-2xl  font-bold pl-3 flex sm:hidden">
               {showSharedByMe > 0 && textLabels.sharedOptions[0]}
            </div>

            <div className={`${checkMembers ? "hidden" : "hidden sm:block"}`}>
               <Button onClick={inviteClick}>Add member</Button>
            </div>
            <div className={`${checkMembers ? "hidden" : "sm:hidden"}`}>
               <div className="add-member-small" onClick={inviteClick}>
                  Add member
               </div>
            </div>
         </div>
         <div className="w-full sm:pl-2 hidden sm:block pt-3">
            <TableShare
               navigate={navigate}
               tab={tab}
               members={filteredMembers}
               isLoading={loading}
               remove={removeFamilyMember}
               edit={edit}
               inviteClick={inviteClick}
               setSelectedPatient={setSelectedPatient}
               openModal={(data) => {
                  setShowDirection(true);
                  setEachCare(data);
                  setSelected(null);
               }}
            />
         </div>
         <div className="w-full sm:pl-2 sm:hidden">
            <TableCareTeamSmall
               navigate={navigate}
               tab={tab}
               members={familyFriendsList}
               isLoading={loading}
               inviteClick={inviteClick}
               openModal={(data, forShare = true) => {
                  setShowDirection(true);
                  setEachCare(data);
                  setShowRemove(forShare);
               }}
            />
         </div>
         {showDirection && (
            <Modal onModalTapped={hide}>
               <GetDirections
                  edit={edit}
                  share={showRemove}
                  eachCare={eachCare}
                  onModalTapped={hide}
                  removeMember={removeFamilyMember}
                  setSelectedPatient={setSelectedPatient}
               />
            </Modal>
         )}
         {invite && (
            <Modal onModalTapped={inviteClick}>
               <InviteFamily
                  selected={selected}
                  loading={loadingInvite}
                  setLoading={setLoadingInvite}
                  onModalTapped={inviteClick}
                  invitePerson={invitePerson}
                  name={nameInvite}
                  relation={relation}
                  setRelation={setRelation}
                  email={emailInvite}
                  setName={(e) => {
                     setNameInvite(capitalizeFirstLetter(e.target.value));
                  }}
                  setEmail={(e) => {
                     setEmailInvite(e);
                  }}
               />
            </Modal>
         )}
      </div>
   );
}

export default Share;
