import React, { useState } from "react";
import "index.css";
import { useQuery, useMutation } from "@apollo/client";
import { connect } from "react-redux";
import Share from "modules/shareFamilyFriends/component/share";
import { ADD_REMOVE_FAMILY_FRIENDS, GET_FAMILY_FRIENDS_DATA, INVITE_PATIENT } from "../actions/familyActions";
import { ShowAlert } from "shared/actions/alert";
import { errorToDisplay } from "shared/actions/helpers";

function ShareCont(props) {
   const { auth } = props;
   const patientId = auth?.userCredentials?.user?.id;
   const [familyFriendsList, setFamilyFriendsList] = useState([]);
   const [selectedPatient, setSelectedPatient] = useState(null);
   const [loadingInvite, setLoadingInvite] = useState(false);
   const [invite, setInvite] = useState(false);

   const { loading, refetch } = useQuery(GET_FAMILY_FRIENDS_DATA, {
      fetchPolicy: "no-cache",
      variables: {
         user: {
            id: patientId,
         },
         type: {
            isActive: true,
            type: "family",
         },
      },
      onCompleted(result) {
         const data = result?.getCareTeam || [];
         setFamilyFriendsList(data);
      },
      onError(err) {
         const errMessage = errorToDisplay(err);
         ShowAlert(errMessage, "error");
      },
   });

   const [addFriendsFamily] = useMutation(INVITE_PATIENT, {
      onCompleted(res) {
         ShowAlert(res.addPatient.status.message);
         setLoadingInvite(false);
         refetch();
         setInvite(false);
      },
      onError(err) {
         const errMessage = errorToDisplay(err);
         ShowAlert(errMessage, "error");
         setLoadingInvite(false);
         setInvite(false);
      },
   });

   const addToFriendsFamily = (familyPayload) => {
      addFriendsFamily({
         variables: {
            patient: {
               contactInformation: {
                  email: familyPayload.email,
                  mobileNumber: familyPayload.mobileNo,
               },
               name: {
                  fullName: familyPayload.name,
               },
            },
            familyInvite: true,
            selectedPatientId: patientId,
            relationship: familyPayload.relationship,
         },
      });
   };

   const [removeFriendsFamily] = useMutation(ADD_REMOVE_FAMILY_FRIENDS, {
      onCompleted(res) {
         ShowAlert(res.addCareMember.message);
         const filteredFamilyMember = familyFriendsList.filter(
            (filter) => filter.careMember.id !== selectedPatient || !filter.isCareTeam
         );
         setFamilyFriendsList(filteredFamilyMember);
         setSelectedPatient(null);
      },
      onError(err) {
         const errMessage = errorToDisplay(err);
         ShowAlert(errMessage, "error");
      },
   });

   const removeFamilyMember = (family) => {
      removeFriendsFamily({
         variables: {
            careTeam: {
               userId: patientId,
            },
            queryType: "delete",
            careMemberId: family.id,
         },
      });
   };

   return (
      <Share
         familyFriendsList={familyFriendsList}
         loading={loading}
         addToFriendsFamily={addToFriendsFamily}
         removeFamilyMember={removeFamilyMember}
         setSelectedPatient={setSelectedPatient}
         setLoadingInvite={setLoadingInvite}
         loadingInvite={loadingInvite}
         invite={invite}
         setInvite={setInvite}
         {...props}
      />
   );
}
const mapStateToProps = (state) => {
   return {
      auth: state.auth,
   };
};

export default connect(mapStateToProps, null)(ShareCont);
