import React from "react";

function SearchBox(props) {
   const { placeholder = "", className = "", text = "", setText = () => {} } = props;

   return (
      <input
         style={{ color: "black" }}
         type="text"
         placeholder={placeholder}
         value={text}
         onChange={(e) => {
            setText(e.target.value);
         }}
         className={`${className} search-input-profile-common`}
      />
   );
}

export default SearchBox;
