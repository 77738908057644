import { client } from "wrappers/apolloWrapper";

export const fetchQuery = async (query, variables = {}, callback = () => {}, error = () => {}, skip = false) => {
   client
      .query({
         fetchPolicy: "no-cache",
         errorPolicy: "all",
         query: query,
         variables: variables,
         skip,
      })
      .then((data) => {
         callback(data);
      })
      .catch((err) => {
         error(err);
      });
};
