import { callSocket } from "shared/actions/socketAction";
import { socketActions, socketSubActions } from "socket/Websocket";
import { getToken } from "actions/index";

export async function getPatientQRCode(callback = () => {}) {
   let token = await getToken();
   const obj = {
      Authorization: token,
      action: socketActions.onboarding,
      subAction: socketSubActions.getPatientQrCode,
   };
   callSocket(obj, (data) => {
      callback(data.data.qrcode);
   });
}
