import React, { useState, useEffect } from "react";
import InputBox from "shared/components/inputBox";
import CustomDatePicker from "shared/components/datePicker/customDatePicker";
import { placeholders } from "shared/constants/loginConst";
import SelectEfficient from "shared/components/select/select";
import { callSocket } from "shared/actions/socketAction";
import { socketActions, socketSubActions } from "socket/Websocket";
import { ShowAlert } from "shared/actions/alert";
import calendarIcon from "images/login/calendar-icon.svg";
import { capitalizeFirstLetter } from "shared/actions/helpers";

const initialOption = { label: "I don’t know", value: "I don’t know" };

export default function SelfOnboardRestricted(props) {
   const {
      selectedDoctor,
      setSelectedDoctor,
      savedPhoneNumber,
      setSavedPhoneNumber,
      fullName,
      setFullName,
      savedEmail,
      setSavedEmail,
      dateOfBirth,
      setDateOfBirth,
      selectedHospital,
   } = props;
   const [doctorList, setDoctorList] = useState([]);

   useEffect(() => {
      getDoctors(selectedHospital);
   }, []);

   const getDoctors = (hospital) => {
      let socketObj = {
         action: socketActions.onboarding,
         subAction: socketSubActions.getDoctorsList,
         hospitalId: hospital?.id,
      };
      callSocket(
         socketObj,
         (res) => {
            if (res?.settings?.status === 1) {
               let docList =
                  res.data?.doctors.map((doctor, i) => {
                     return {
                        label: doctor?.name,
                        value: doctor?.id,
                     };
                  }) || [];
               setDoctorList([initialOption, ...docList]);
            } else {
               setDoctorList([]);
               ShowAlert("Could not fetch list.", "error");
            }
         },
         (err) => {
            ShowAlert(err?.message, "error");
         }
      );
   };
   return (
      <div>
         <div className=" w-[100%] mt-5 mb-11 text-center sm:w-3/4 m-auto pt-4">
            <InputBox
               placeholder={placeholders.fullName}
               type="text"
               value={fullName}
               onChange={(e) => {
                  let properName = capitalizeFirstLetter(e.target.value);
                  setFullName(properName);
               }}
            />
            <InputBox
               placeholder={placeholders.mobile}
               type="number"
               className="mt-3"
               value={savedPhoneNumber}
               onChange={(e) => {
                  setSavedPhoneNumber(e.target.value);
               }}
            />
            <InputBox
               placeholder={placeholders.email}
               type="text"
               className="mt-3"
               value={savedEmail}
               onChange={(e) => {
                  setSavedEmail(e.target.value);
               }}
            />

            <div className="relative w-full pb-4 pt-2">
               <div>
                  <CustomDatePicker
                     id="patient-dob"
                     onSelect={(e) => {
                        setDateOfBirth(e?.getTime());
                     }}
                     onChange={(e) => {
                        setDateOfBirth(e?.getTime());
                     }}
                     value={dateOfBirth}
                  />
               </div>
               <img
                  className="absolute cursor-pointer"
                  style={{ right: "15px", bottom: "30px" }}
                  src={calendarIcon}
                  alt=""
                  onClick={() =>
                     document.getElementById("patient-dob") && document.getElementById("patient-dob").click()
                  }
               />
            </div>
            <SelectEfficient
               options={doctorList || []}
               value={selectedDoctor}
               onChange={(e) => {
                  setSelectedDoctor(e);
               }}
               placeholder={placeholders.selectDoctor}
            />
         </div>
      </div>
   );
}
