import React from "react";
import Login from "modules/login/component/loginComp";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "redux/actions/auth.action";

const LoginCont = (props) => {
   return <Login {...props} />;
};

const mapStateToProps = (state) => {
   return {
      auth: state.auth,
   };
};
const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
      {
         savenorthwelluserobj: actions.savenorthwelluserobj,
         saveusercredentials: actions.saveusercredentials,
      },
      dispatch
   );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginCont);
