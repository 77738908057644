import React, { memo, useState } from "react";
import { getParam, formatPhoneNumberBackend } from "shared/actions/helpers";
import ProfileDetails from "shared/components/profile/profileDetails";
import { emptyStatesText, optionsShare } from "shared/constants/const";
import { removeFriendsFamily } from "../actions/familyActions";
import Dropdowntoggle from "shared/components/dropDown/dropDownCustom";
import options from "images/common/options.svg";
import EmptyStateComp from "shared/components/emptyStates/emptyState";
import ShareImg from "images/emptyStates/share.svg";

const tableHeaderStyle = {
   border: "1px solid #ced4da",
   borderRight: "none",
   borderLeft: "none",
   background: "rgba(224, 224, 224, 0.2)",
   height: 44,
   display: "flex",
   alignItems: "center",
   justifyContent: "start",
   paddingLeft: 10,
   fontSize: "16px",
};
const tableHeaderConst = {
   border: "1px solid #ced4da",
   borderRight: "none",
   borderLeft: "none",
   background: "rgba(224, 224, 224, 0.2)",
   height: 44,
   display: "flex",
   alignItems: "center",
};

const tableHeaderStyleEx = {
   border: "1px solid #ced4da",
   borderRight: "none",
   borderLeft: "none",
   background: "rgba(224, 224, 224, 0.2)",
   height: 44,
   display: "flex",
   alignItems: "center",
   justifyContent: "start",
};

const TableCareTeam = memo(
   ({
      members = [],
      isLoading = false,
      openModal = () => {},
      remove,
      tab,
      navigate,
      setSelectedPatient,
      edit,
      inviteClick,
   }) => {
      const isShare = tab === 1;
      const [memberId, setMemberId] = useState("");
      if (isLoading)
         return (
            <div className="post-card p-4">
               {Array(10)
                  .fill()
                  .map((o, index) => (
                     <div
                        key={index}
                        className="flex animate-pulse flex-row items-center h-full justify-center space-x-1 rounded-lg "
                     >
                        <div className="flex flex-col space-y-3 w-full px-2 py-1 rounded-lg">
                           <div className="w-full bg-gray-300 h-10 rounded-md mb-2"></div>
                        </div>
                     </div>
                  ))}
            </div>
         );
      else if (members.length === 0)
         return (
            <div className="flex items-center justify-center w-full h-[57vh] text-bold text-large text-grey5 no-content-available">
               <EmptyStateComp
                  src={ShareImg}
                  headerText={emptyStatesText.emptyShare}
                  btnText={emptyStatesText.emptyShareBtnTxt}
                  description={emptyStatesText.emptyShareText}
                  onClick={inviteClick}
               />
            </div>
         );
      else
         return (
            <table className="w-full hidden sm:table" cellPadding="0" cellSpacing="0" border="0">
               <thead className="text-small">
                  <tr>
                     <th width="33%">
                        <div
                           className="table-head"
                           style={{
                              ...tableHeaderStyle,
                              borderTopLeftRadius: "8px",
                              borderLeft: "1px solid #ced4da",
                              paddingLeft: 15,
                           }}
                        >
                           Name
                        </div>
                     </th>
                     <th width={isShare ? "30%" : "20%"}>
                        <div className="table-head" style={{ ...tableHeaderConst }}>
                           Relation
                        </div>
                     </th>
                     <th width={isShare ? "30%" : "33%"}>
                        <div
                           className="table-head"
                           style={
                              isShare
                                 ? {
                                      ...tableHeaderStyleEx,
                                      borderRight: "1px solid #ced4da",
                                      borderTopRightRadius: "8px",
                                      paddingLeft: "5px",
                                   }
                                 : { ...tableHeaderConst }
                           }
                        >
                           Contact info
                        </div>
                     </th>
                     {!isShare && (
                        <th>
                           <div
                              className="table-head"
                              style={{
                                 ...tableHeaderStyleEx,
                                 borderRight: "1px solid #ced4da",
                                 borderTopRightRadius: "8px",
                                 paddingLeft: "5px",
                              }}
                           >
                              Action
                           </div>
                        </th>
                     )}
                  </tr>
               </thead>
               <tbody>
                  {members.map((o, i) => {
                     const { careMember } = o;
                     const contactInfo = careMember?.contactInformation;
                     const initialsApi = careMember?.name?.initials || false;
                     const color = careMember?.colorCode || window.initialColors[0];
                     const fullName = careMember?.name?.fullName;
                     const isEmail = contactInfo?.email || false;
                     const isMobileNo = contactInfo?.mobileNumber || false;
                     const relation = o?.relationship;
                     return (
                        <>
                           <tr
                              onClick={() => {
                                 if (tab === 0) {
                                    openModal(o);
                                 } else {
                                    navigate(`/sharing/${careMember?.id}`);
                                 }
                              }}
                              key={o?.id || i}
                              id={`dropdownOption-${careMember?.id}`}
                              className="cursor-pointer hover:bg-light-blue"
                           >
                              <td className="pointer py-3">
                                 <ProfileDetails
                                    src={`${process.env.REACT_APP_PROFILE_URL}/${careMember?.id}`}
                                    className="ml-4"
                                    title={fullName}
                                    post={o?.isCareTeam}
                                    color={color}
                                    initialsApi={initialsApi}
                                    name={fullName}
                                 />
                              </td>
                              <td className="table-data">{getParam(relation)}</td>
                              <td className="table-data">
                                 <div className="flex flex-col gap-y-1">
                                    {isEmail && <div>{getParam(isEmail)}</div>}
                                    {isMobileNo && <div>{formatPhoneNumberBackend(isMobileNo)}</div>}
                                 </div>
                              </td>
                              {!isShare && (
                                 <td>
                                    <Dropdowntoggle
                                       options={optionsShare}
                                       anchor={true}
                                       careId={careMember?.id}
                                       memberId={memberId}
                                       setMemberId={setMemberId}
                                       onChange={(clicked) => {
                                          if (clicked.id === 0) {
                                             edit(o);
                                          } else {
                                             removeFriendsFamily(null, o, remove, setSelectedPatient);
                                          }
                                       }}
                                       view={() => {
                                          return <img src={options} alt="more-options" />;
                                       }}
                                    />
                                 </td>
                              )}
                           </tr>
                        </>
                     );
                  })}
               </tbody>
            </table>
         );
   }
);

export default TableCareTeam;
