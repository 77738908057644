import React from "react";
import { ageForDob, formatPhoneNumberBackend, getParam, getFormattedDate } from "shared/actions/helpers";
import Avatar from "shared/components/avatar";
import SkeletonProfile from "shared/components/skeletionProfile";
import { sharedPatientConst } from "shared/constants/sharedPatient";
const header = "ml-[45px] text-[#4F4F4F] text-[15px]";
const subHeader = "inline text-[#8C8C8C] pl-2 cursor-pointer";

export default function PatientHeader({ patientData }) {
   const initialsApi = patientData?.name?.initials || "";
   const color = patientData?.colorCode || window.initialColors[0];
   const name = patientData?.name?.fullName || "";
   const isDob = patientData?.dob || false;
   const number = patientData?.contactInformation?.mobileNumber;
   const showCard = patientData !== null;
   const email = patientData?.contactInformation?.email;
   return (
      <div className={`pl-[5px] mt-4 h-[80px]`}>
         {showCard ? (
            <>
               <div className="flex items-center text-[#17182D] text-[27px] ">
                  <Avatar
                     src={`${process.env.REACT_APP_PROFILE_URL}/${patientData?.id}`}
                     className="cursor-pointer"
                     bgColor={color}
                     radius={32}
                     initialsApi={initialsApi}
                     name={name}
                  />
                  <div className="ml-3 text-[#17182D] font-semibold">{name}</div>
                  {isDob && <div className="ml-3 text-[#17182D] font-semibold">{"(" + ageForDob(isDob) + ")"}</div>}
               </div>
               <div className="flex pt-2">
                  <div className={header}>
                     {sharedPatientConst.dob}
                     <span className={subHeader}>{getFormattedDate(isDob)}</span>
                  </div>
                  <div className={header}>
                     {sharedPatientConst.mob}
                     <span
                        onClick={() => {
                           if (number) {
                              window.location.href = `tel:${number}`;
                           }
                        }}
                        className={subHeader}
                     >
                        {formatPhoneNumberBackend(number)}
                     </span>
                  </div>
                  <div className={header}>
                     {sharedPatientConst.email}
                     <span
                        onClick={() => {
                           if (email) {
                              window.location.href = `mailto:${email}`;
                           }
                        }}
                        className={subHeader}
                     >
                        {getParam(email)}
                     </span>
                  </div>
               </div>
            </>
         ) : (
            <SkeletonProfile />
         )}
      </div>
   );
}
