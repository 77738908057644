import React from "react";

const Row = ({ view = () => {}, title, val = false, action = false, open = () => {}, selection, disabled = false }) => {
   return (
      <div className="sm:flex items-center justify-between py-5 sm:pl-2">
         <div className="text-lg text-black font-medium">{title}</div>
         <div className="justify-between mt-2 sm:mt-0 sm:justify-start flex text-base font-normal items-center">
            <div className={` ${!val && "cursor-pointer"}  pr-5`}>{val ? val : view()}</div>
            <div className="flex items-center">
               <div
                  onClick={() => {
                     if (!disabled) {
                        open(selection);
                     }
                  }}
                  className={`${disabled && "text-gray-300"} ${action ? "underline" : "ml-[5px]"} cursor-pointer `}
               >
                  {action ? action : view()}
               </div>
            </div>
         </div>
      </div>
   );
};

export default Row;
