import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import HeaderSmall from "./headerSmall";
import PatientHeader from "./patientHeader";
import SharedTabs from "./sharedTabs";
import CareTeamCont from "modules/careTeam/container/careTeamCont";
import ShareTeam from "./tabs/shareTeamDetails";
import GetDirectionsGQL from "modules/careTeam/component/directionsGql";
import Modal from "shared/components/modal/modal";
import { GET_USER_DATA } from "actions/graphqlActions/userDataQuery";
import { useQuery } from "@apollo/client";
import { errorToDisplay } from "shared/actions/helpers";
import { ShowAlert } from "shared/actions/alert";
import HomeCont from "modules/home/container/homeCont";

export default function SharedPatientDetails() {
   const [eachCare, setEachCare] = useState(null);
   const [patientData, setPatientData] = useState(null);
   const [tab, setTab] = useState(0);
   const { id } = useParams();
   const navigate = useNavigate();

   const showModal = eachCare !== null;
   const toggleModal = () => {
      setEachCare(null);
   };

   useQuery(GET_USER_DATA, {
      variables: {
         options: {
            id: id,
         },
      },
      onCompleted(result) {
         setPatientData(result.getProfile);
      },
      onError(error) {
         let msg = errorToDisplay(error);
         ShowAlert(msg, "error");
         navigate("/sharing");
      },
   });

   const openModalGql = (data) => {
      setEachCare(data);
   };
   return (
      <div className="sm:mx-2">
         <div className="hidden sm:block">
            <PatientHeader patientData={patientData} />
         </div>
         <div className="sm:hidden">
            <HeaderSmall
               patientData={patientData}
               navigate={() => {
                  navigate("/sharing");
               }}
            />
         </div>
         <SharedTabs
            selected={tab}
            onClick={(e) => {
               setTab(e);
            }}
         />
         <div className="sm:pt-5">
            {tab === 0 && <HomeCont id={id} />}
            {tab === 1 && <CareTeamCont id={id} openModal={openModalGql} />}
            {tab === 2 && <ShareTeam id={id} openModal={openModalGql} />}
         </div>

         {showModal && (
            <Modal onModalTapped={toggleModal}>
               <GetDirectionsGQL eachCare={eachCare} onModalTapped={toggleModal} />
            </Modal>
         )}
      </div>
   );
}
