import React from "react";
import FreshChat from "react-freshchat";
class FreshChatComp extends React.Component {
   render() {
      const { user } = this.props;
      const email = user.email;
      const fullName = user.name;
      return (
         <div>
            <FreshChat
               token={"be80a81c-98f0-47a3-8962-d7bd692bc2d7"}
               email={email}
               firstName={fullName}
               onInit={(widget) => {
                  widget.destroy();
                  widget.user.clear();
                  widget.init({
                     token: "be80a81c-98f0-47a3-8962-d7bd692bc2d7",
                     host: "https://wchat.freshchat.com",
                     externalId: user.id,
                     email: email,
                     firstName: fullName,
                  });
                  widget.user.setProperties({
                     email: email,
                     firstName: fullName,
                     externalId: user.id,
                  });
               }}
            />
         </div>
      );
   }
}
export default FreshChatComp;
