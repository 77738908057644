import React from "react";
import ReactDOM from "react-dom";
import RouterCont from "modules/router/container/routerContainer";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Swal from "sweetalert2";

window.addEventListener("load", function () {
   function updateOnlineStatus(event) {
      var condition = navigator.onLine ? "online" : "offline";
      if (condition === "offline") {
         let timerInterval;
         let time = 10000;
         Swal.fire({
            title: "There is a problem with your internet connection.",
            html: "This alert will close in <strong></strong> seconds.<br/><br/>",
            timer: time,
            onBeforeOpen: () => {
               Swal.showLoading();
               timerInterval = setInterval(() => {
                  let increaseTime = "";
                  if (Swal.getTimerLeft() < 500) {
                     increaseTime = time + 5000;
                     Swal.increaseTimer(increaseTime);
                  }

                  Swal.getContent().querySelector("strong").textContent = (Swal.getTimerLeft() / 1000).toFixed(0);
               }, 100);
            },
            onClose: () => {
               clearInterval(timerInterval);
            },
         });
      } else {
         window.location.reload();
      }
   }
   window.addEventListener("online", updateOnlineStatus);
   window.addEventListener("offline", updateOnlineStatus);
});

ReactDOM.render(
   <div id="div-scroll-top" className="h-[100vh] bg-white scroll-custom ">
      <Provider store={store}>
         <RouterCont />
      </Provider>
   </div>,
   document.getElementById("root")
);
