import { LIMIT } from "shared/constants/sharedPatient";
import * as types from "../../modules/home/constants/contentConstants";

const INITIAL_STATE = {
   list: [],
   loading: true,
   limit: LIMIT,
   offset: 0,
   totalCount: 0,
   searchTerm: "",
   hasMore: false,
};

export default function contentActivitylist(state = INITIAL_STATE, action) {
   switch (action.type) {
      case types.SET_PATIENT_CONTENT:
         return {
            ...action.payload,
         };
      case types.SET_CONTENT_SEARCH_TERM:
         return {
            ...state,
            searchTerm: action.payload,
         };
      case types.SET_INITIAL_STATE:
         return {
            ...INITIAL_STATE,
         };
      default:
         return state;
   }
}
