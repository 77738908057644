import { getAuth, signInWithRedirect, OAuthProvider } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";

export const googleAuth = () => {
   localStorage.setItem("socialLogin", "true");
   const provider = new GoogleAuthProvider();
   const auth = getAuth();
   signInWithRedirect(auth, provider);
};

export const appleAuth = () => {
   localStorage.setItem("socialLogin", "true");
   const provider = new OAuthProvider("apple.com");
   const auth = getAuth();
   signInWithRedirect(auth, provider);
};
