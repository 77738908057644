import React from "react";

export default function SkeletonProfile() {
   return (
      <div className="flex animate-pulse flex-row items-center h-[80px]  space-x-5">
         <div className="w-12 bg-gray-200 h-12 rounded-full "></div>
         <div className="flex flex-col space-y-3">
            <div className="w-[200px] bg-gray-200 h-6 rounded-md "></div>
            <div className="w-[500px] bg-gray-200 h-6 rounded-md "></div>
         </div>
      </div>
   );
}
