import React from "react";
import { options } from "shared/constants/const";

export default function PanelResponsive({ path, navigate }) {
   return (
      <div className="sm:hidden flex items-center justify-around mt-2">
         {options.map((map) => (
            <div
               key={map.id}
               onClick={() => {
                  navigate(map.link);
               }}
               className={` ${
                  map.link === path ? "color-playback-base-responsive  font-bold " : "font-medium"
               } text-sm cursor-pointer pb-2`}
            >
               {map.text}
            </div>
         ))}
      </div>
   );
}
