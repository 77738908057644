import { combineReducers } from "redux";
import auth from "./auth.reducers";
import darkly from "./darklyReducer";
import contentActivitylist from "./patientContentReducer";

const rootReducer = combineReducers({
   auth,
   darkly,
   contentActivitylist,
});

export default rootReducer;
