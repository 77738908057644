import { settingsConst } from "shared/constants/settingsConst";
import { gql } from "@apollo/client";

export const UPDATE_PROFILE = gql`
   mutation Mutation($user: UserInput) {
      updateProfile(user: $user) {
         status {
            message
            success
         }
      }
   }
`;

export const UPDATE_PROFILE_IMG = gql`
   mutation Mutation($updateProfileImageId: ID, $operationType: String) {
      updateProfileImage(id: $updateProfileImageId, operationType: $operationType) {
         message
         url
      }
   }
`;

export const getTitle = (selected) => {
   switch (selected) {
      case 1:
         return settingsConst.changeName;
      case 2:
         return settingsConst.addPhoto;
      case 4:
         return settingsConst.changeEmail;
      case 5:
         return settingsConst.changeNum;
      case 6:
         return settingsConst.lookGood;
      default:
         return "";
   }
};
