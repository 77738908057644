import React from "react";
export default function ButtonCancel({ onClick, disabled, children, className }) {
   return (
      <button
         className={`bg-cancel text-[#4F4F4F] text-sm font-semibold w-full p-2 rounded-md ${className}`}
         disabled={disabled}
         onClick={onClick}
      >
         {children}
      </button>
   );
}
