import React from "react";

function SkeletonLoader(props) {
   const { arrayLength } = props;
   return Array(arrayLength)
      .fill()
      .map((o, index) => (
         <div
            key={index}
            className="border-line bg-gray-200 animate-pulse my-4 flex rounded-lg h-full loading-shade"
         ></div>
      ));
}

export default SkeletonLoader;
