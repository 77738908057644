import React from "react";
import Switch from "shared/components/switch";
export default function EachRow({ text, value, onChange }) {
   return (
      <div className="flex items-center justify-between py-[25px]">
         <div className="font-semibold text-[22px] text-[ #17182D]">{text}</div>
         <div>
            <Switch value={value} onChange={onChange} />
         </div>
      </div>
   );
}
