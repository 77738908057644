export const settingsConst = {
   settings: "Settings",
   name: "Name",
   photo: "Photo",
   dob: "Date of birth",
   email: "Email",
   num: "Mobile Number",
   code: "My code",
   changeName: "Change your name",
   changeNum: "Change your Mobile Number",
   addPhoto: "Add an account photo",
   lookGood: "Looking good!",
   delPhoto: "Are you sure you want to delete your account photo?",
   changeEmail: "Change your email",
   edit: "Edit",
   show: "Show",
   media: "Media uploaded successfully",
   delete: "delete",
   capsDel: "Delete",
   upload: "upload",
};
export const placeholders = {
   fname: "First name",
   mname: "Middle name",
   lname: "Last name",
   drag: "Drag & Drop your files here or Browse Files",
   num: "Enter Mobile Number",
   email: "Enter email",
};

export const validations = {
   nameError: "First name and last name must have a mininmun of 2 characters",
   selectFile: "Please select a file",
   onlyPhoto: "Please select an Image",
};
