import React, { useEffect, useState } from "react";
import PatientLogin from "./PatientLogin";
import { socketActions, socketSubActions } from "socket/Websocket";
import initAutocomplete from "shared/components/googleMaps";
import LoadingIndicator from "shared/components/loader";
import { ShowAlert, showSwal } from "shared/actions/alert";
import { checkEmail, unformatNumber, formatDate, nameRegex } from "shared/actions/helpers";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { textConst } from "shared/constants/const";

import { useNavigate } from "react-router-dom";
import { loginConst, placeholders } from "shared/constants/loginConst";
import { resendOtpSignUp } from "actions/login/loginActions";

import { checkUserExistsSocial } from "actions/login/loginActions";
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";

const PatientComponent = (props) => {
   const {
      savenorthwelluserobj,
      saveusercredentials,
      email = "",
      phone = "",
      backToLogin = (newForm = false) => {
         if (newForm) {
            setStep(12);
         } else {
            setStep(10);
         }
      },
      fullNameFromFirebase = "",
   } = props;
   const isSocialLogin = localStorage.getItem("socialLogin") === "true" || false;
   const navigate = useNavigate();
   const [step, setStep] = useState(email || phone ? 12 : 0);
   const [keyAuth, setKeyAuth] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const [savedEmail, setSavedEmail] = useState("");
   const [fullName, setFullName] = useState(fullNameFromFirebase || "");
   const [dateOfBirth, setDateOfBirth] = useState(null);
   const [savedPhoneNumber, setSavedPhoneNumber] = useState("");
   const [hospitals, setHospitals] = useState([]);
   const [location, setLocation] = useState(null);
   const [otp, setOtp] = useState("");
   const [isPhone, setIsPhone] = useState(true);
   const [selectedDoctor, setSelectedDoctor] = useState(null);
   const [selectedHospital, setSelectedHospital] = useState(null);

   useEffect(() => {
      setSavedEmail(email);
      setSavedPhoneNumber(phone);
   }, []);

   useEffect(() => {
      window.scrollTo(0, 0);
   }, [step]);

   const checkUserExists = () => {
      const phoneNumberWithoutFormat = unformatNumber(savedPhoneNumber);
      let obj = {};
      if (isPhone) {
         if (phoneNumberWithoutFormat.length < 10) {
            ShowAlert(textConst.phone, textConst.error);
            return;
         }

         obj = {
            action: socketActions.auth,
            subAction: socketSubActions.checkUser,
            phone: phoneNumberWithoutFormat.substring(1),
         };
      } else {
         if (!checkEmail(savedEmail)) {
            ShowAlert(textConst.email, textConst.error);
            return;
         }
         obj = {
            action: socketActions.auth,
            subAction: socketSubActions.checkUser,
            email: savedEmail,
         };
      }

      setIsLoading(true);

      window.socket.send(obj, (resultStatus) => {
         setIsLoading(false);
         if (resultStatus?.data?.isUserExist === false) {
            nextStep();
         } else {
            const message = resultStatus.settings.message;
            if (
               message === "A provider already exist with this email address. Please use an alternate email." ||
               message === "Not a Valid US phone number"
            ) {
               ShowAlert(message, textConst.error);
            } else {
               showSwal(
                  "",
                  message,
                  () => {
                     localStorage.setItem("localEmail", savedEmail);
                     localStorage.setItem("localPhone", savedPhoneNumber);
                     navigate("/");
                  },
                  textConst.yes,
                  textConst.no
               );
            }
         }
      });
   };

   const gethospitals = () => {
      const obj = {
         action: socketActions.onboarding,
         subAction: socketSubActions.getHospitals,
      };
      window.socket.send(obj, (resultStatus) => {
         if (resultStatus?.settings.status === 1) {
            let data = resultStatus?.hospitals?.map((map, i) => {
               if (i === 0) {
                  const obj = { label: map.name, data: map, lat: map.lat, long: map.long };
                  setLocation(obj);
                  initAutocomplete(obj);
               }
               return { label: map.name, data: map, lat: map.lat, long: map.long };
            });
            setHospitals(data);
         } else {
         }
      });
   };

   const registerUser = () => {
      setIsLoading(true);
      const obj = {
         action: socketActions.onboarding,
         subAction: socketSubActions.verifyOTP,
         key: keyAuth,
         otp: otp,
      };
      window.socket.send(obj, (resultStatus) => {
         if (resultStatus?.settings.status === 1) {
            firebase
               .auth()
               .signInWithCustomToken(resultStatus.data.customToken)
               .then((storeDataRedux) => {
                  let userData = { user: resultStatus.data.userData };
                  savenorthwelluserobj(JSON.parse(JSON.stringify(storeDataRedux)));
                  saveusercredentials(userData);
                  navigate("/");
               })
               .catch((error) => {
                  console.log(error);
               });
         } else {
            setIsLoading(false);
            ShowAlert(resultStatus.settings.message, textConst.error);
         }
      });
   };

   const continueToOtp = (skip = false) => {
      const hospitalId = skip ? "" : location !== null ? location.data.id : "";
      setIsLoading(true);
      const obj = {
         action: socketActions.onboarding,
         subAction: socketSubActions.formSubmittedV2,
         name: fullName,
         email: savedEmail,
         phone: unformatNumber(savedPhoneNumber),
         dob: formatDate(dateOfBirth),
         hospitalId: hospitalId || selectedHospital?.id,
      };
      window.socket.send(obj, (resultStatus) => {
         if (resultStatus?.settings.status === 1) {
            setStep(step + 2);
            setIsLoading(false);
            ShowAlert(resultStatus.settings.message);
            setKeyAuth(resultStatus.data.key);
            // nextStep();
         } else {
            setIsLoading(false);
            ShowAlert(resultStatus.settings.message, textConst.error);
         }
      });
   };

   const SubmitForm = () => {
      setIsLoading(true);
      const obj = {
         action: socketActions.onboarding,
         subAction: socketSubActions.formSubmittedV2,
         name: fullName,
         email: savedEmail,
         phone: unformatNumber(savedPhoneNumber),
         dob: formatDate(dateOfBirth),
         hospitalId: selectedHospital?.id,
         doctorId: selectedDoctor?.value,
      };
      window.socket.send(obj, (resultStatus) => {
         if (resultStatus?.settings.status === 1) {
            setStep(11);
            setIsLoading(false);
         } else {
            setIsLoading(false);
            ShowAlert(resultStatus.settings.message, textConst.error);
         }
      });
   };

   const nextStep = () => {
      setStep(step + 12);
   };
   const moveBack = (isNewForm = false) => {
      if (step === 1) {
         if (isPhone && savedPhoneNumber) {
            setSavedEmail("");
         } else {
            setSavedPhoneNumber("");
         }
         setDateOfBirth(null);
         setSelectedHospital(null);
         if (step === 1) {
            setStep(12);
         }
      } else if (step === 12) {
         if (email || phone) {
            backToLogin();
         } else {
            setStep(step - 12);
         }
      } else if (step === 10) {
         if (isPhone && savedPhoneNumber) {
            setSavedEmail("");
         } else {
            setSavedPhoneNumber("");
         }
         setDateOfBirth(null);
         setSelectedHospital(null);
         setSelectedDoctor(null);
         setStep(step + 2);
      } else if (step === 3) {
         setStep(step - 2);
      } else {
         setStep(step - 1);
      }
   };

   useEffect(() => {
      if (isSocialLogin) {
         setIsLoading(true);
      }
      checkSocialLogin();
   }, []);

   const checkSocialLogin = () => {
      firebase
         .auth()
         .getRedirectResult()
         .then((result) => {
            if (result.credential) {
               const email = result.user.email;
               setSavedEmail(email);
               localStorage.removeItem("socialLogin");
               setFullName(result?.user?.displayName || "");
               firebase
                  .auth()
                  .currentUser.getIdToken(true)
                  .then(function (idToken) {
                     checkUserExistsSocial(idToken, (res) => {
                        if (res.data.isUserExist) {
                           setIsLoading(false);
                           showSwal(
                              "",
                              res.settings.message,
                              () => {
                                 localStorage.setItem("localEmail", savedEmail);
                                 localStorage.setItem("firebaseId", idToken);
                                 navigate("/");
                              },
                              textConst.yes,
                              textConst.no
                           );
                        } else {
                           setIsLoading(false);
                           nextStep();
                        }
                     });
                  });
            } else {
               if (isSocialLogin) {
                  setIsLoading(false);
                  localStorage.removeItem("socialLogin");
               }
            }
         });
   };

   const checkSafariSocialLogin = (googleLogin = true) => {
      let provider;
      localStorage.setItem("socialLogin", "true");
      if (googleLogin) {
         provider = new GoogleAuthProvider();
      } else {
         provider = new OAuthProvider("apple.com");
      }
      const auth = getAuth();
      signInWithPopup(auth, provider)
         .then((result) => {
            if (result) {
               const email = result.user.email;
               setSavedEmail(email);
               setFullName(result?.user?.displayName || "");
               localStorage.removeItem("socialLogin");
               setIsLoading(true);
               firebase
                  .auth()
                  .currentUser.getIdToken(true)
                  .then(function (idToken) {
                     checkUserExistsSocial(idToken, (res) => {
                        if (res.data.isUserExist) {
                           setIsLoading(false);
                           showSwal(
                              "",
                              res.settings.message,
                              () => {
                                 localStorage.setItem("localEmail", savedEmail);
                                 localStorage.setItem("firebaseId", idToken);
                                 navigate("/");
                              },
                              textConst.yes,
                              textConst.no
                           );
                        } else {
                           setIsLoading(false);
                           nextStep();
                        }
                     });
                  });
            } else {
               if (isSocialLogin) {
                  setIsLoading(false);
                  localStorage.removeItem("socialLogin");
               }
            }
         })
         .catch(function (error) {
            console.log({ error });
         });
   };

   const resendOtp = () => {
      setIsLoading(true);
      resendOtpSignUp(keyAuth, () => {
         setIsLoading(false);
      });
   };

   const clearStateVariables = () => {
      setIsLoading(false);
      setFullName("");
      setSavedEmail("");
      setDateOfBirth(null);
      setSavedPhoneNumber("");
      setSelectedHospital(null);
      setSelectedDoctor(null);
   };

   const createAcc = loginConst.patientOnboard.createAcc;
   const createAccSubtitle = loginConst.patientOnboard.createAccSubtitle;
   const enterDetails = loginConst.patientOnboard.enterDetails;
   const enterDetailsSubtitle = loginConst.patientOnboard.enterDetailsSubtitle;
   const codeSent = loginConst.patientOnboard.codeSent;

   return (
      <div
         className={` ${isLoading ? "flex" : "sm:flex"} ${
            step === 0 ? "pt-10" : "pt-1"
         }  sm:pt-0  justify-center items-center  min-h-screen  ${
            step === 11 ? "bg-[#043293] sm:base-blue w-full p-0 flex" : "bg-[white] sm:base-back p-5"
         }`}
      >
         {isLoading ? (
            <LoadingIndicator />
         ) : (
            {
               0: (
                  <PatientLogin
                     isPhone={isPhone}
                     setIsPhone={() => {
                        setIsPhone(!isPhone);
                        setSavedEmail("");
                        setSavedPhoneNumber("");
                     }}
                     nextStepHandler={checkUserExists}
                     healthcareTitle={createAcc}
                     healthcareContent={createAccSubtitle}
                     step={step}
                     savedPhoneNumber={savedPhoneNumber}
                     setSavedPhoneNumber={setSavedPhoneNumber}
                     savedEmail={savedEmail}
                     setSavedEmail={setSavedEmail}
                     checkSafariSocialLogin={checkSafariSocialLogin}
                  />
               ),
               1: (
                  <PatientLogin
                     nextStepHandler={() => {
                        if (!nameRegex.test(fullName)) {
                           ShowAlert(textConst.fullname, textConst.error);
                        } else if (unformatNumber(savedPhoneNumber).length < 11) {
                           ShowAlert(textConst.phone, textConst.error);
                        } else if (!checkEmail(savedEmail)) {
                           ShowAlert(textConst.email, textConst.error);
                        } else if (dateOfBirth === null) {
                           ShowAlert(textConst.dob, textConst.error);
                        } else {
                           continueToOtp();
                        }
                     }}
                     healthcareTitle={enterDetails}
                     healthcareContent={enterDetailsSubtitle}
                     step={step}
                     savedPhoneNumber={savedPhoneNumber}
                     fullName={fullName}
                     savedEmail={savedEmail}
                     setSavedEmail={setSavedEmail}
                     setFullName={setFullName}
                     setSavedPhoneNumber={setSavedPhoneNumber}
                     dateOfBirth={dateOfBirth}
                     setDateOfBirth={setDateOfBirth}
                     goBack={() => moveBack()}
                  />
               ),
               3: (
                  <PatientLogin
                     resendOtp={resendOtp}
                     nextStepHandler={registerUser}
                     savedPhoneNumber={savedPhoneNumber}
                     healthcareTitle={codeSent}
                     step={step}
                     otp={otp}
                     setOtp={setOtp}
                     goBack={moveBack}
                  />
               ),
               10: (
                  <PatientLogin
                     selectedHospital={selectedHospital}
                     selectedDoctor={selectedDoctor}
                     setSelectedDoctor={setSelectedDoctor}
                     savedPhoneNumber={savedPhoneNumber}
                     setSavedPhoneNumber={setSavedPhoneNumber}
                     fullName={fullName}
                     setFullName={setFullName}
                     savedEmail={savedEmail}
                     setSavedEmail={setSavedEmail}
                     dateOfBirth={dateOfBirth}
                     setDateOfBirth={setDateOfBirth}
                     nextStepHandler={() => {
                        if (!nameRegex.test(fullName)) {
                           ShowAlert(textConst.fullname, textConst.error);
                        } else if (unformatNumber(savedPhoneNumber).length < 11) {
                           ShowAlert(textConst.phone, textConst.error);
                        } else if (!checkEmail(savedEmail)) {
                           ShowAlert(textConst.email, textConst.error);
                        } else if (dateOfBirth === null) {
                           ShowAlert(textConst.dob, textConst.error);
                        } else if (selectedDoctor === null) {
                           ShowAlert(textConst.doctor, textConst.error);
                        } else {
                           SubmitForm();
                        }
                     }}
                     healthcareTitle={loginConst.patientOnboard.signUp}
                     healthcareContent={selectedHospital?.name + loginConst.patientOnboard.requestSubmit}
                     btnText={placeholders.submit}
                     step={10}
                     goBack={() => moveBack(true)}
                  />
               ),
               11: (
                  <PatientLogin
                     nextStepHandler={() => {
                        setStep(0);
                        clearStateVariables();
                     }}
                     healthcareTitle={loginConst.patientOnboard.signUp}
                     // btnText={placeholders.signin}
                     className="bg-[#E0E0E0] rounded-[8px] text-[#4F4F4F] text-[17px] font-semibold"
                     step={11}
                     goBack={moveBack}
                  />
               ),
               12: (
                  <PatientLogin
                     nextStepHandler={() => {
                        if (selectedHospital === null) {
                           ShowAlert(loginConst.patientOnboard.selectHospitalText, "error");
                           return;
                        }
                        if (selectedHospital.isSelfOnboardAllowed) {
                           setStep(1);
                        } else {
                           setStep(10);
                        }
                     }}
                     setSelectedHospital={setSelectedHospital}
                     selectedHospital={selectedHospital}
                     healthcareTitle={loginConst.patientOnboard.healthcareFacility}
                     healthcareContent={loginConst.patientOnboard.enterDetailsSubtitle}
                     step={step}
                     savedPhoneNumber={savedPhoneNumber}
                     fullName={fullName}
                     savedEmail={savedEmail}
                     setSavedEmail={setSavedEmail}
                     setFullName={setFullName}
                     setSavedPhoneNumber={setSavedPhoneNumber}
                     dateOfBirth={dateOfBirth}
                     setDateOfBirth={setDateOfBirth}
                     goBack={moveBack}
                  />
               ),
            }[step]
         )}
      </div>
   );
};

export default PatientComponent;
